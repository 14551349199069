import React from "react";
import { Form, Formik } from "formik";
import { useAppState } from "@bluechilli/bcstatemachine";

import { TextField, SubmitButton } from "@bluechilli/chilliforms";
import { Link, navigate } from "@reach/router";

import signupFormValidationSchema from "./signupFormValidationSchema";

function LoginForm() {
  const { signup, login } = useAppState("user")[1];

  return (
    <>
      <Formik
        validationSchema={signupFormValidationSchema}
        initialValues={{
          email: "",
          password: "",
          name: "",
          surname: "",
          company: "",
          repeatPassword: ""
        }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          const { email, password, name, surname, company } = values;

          signup(name, surname, company, email, password)
            .then(_ => {
              login(email, password);
            })
            .then(_ => {
              navigate("/");
            })
            .catch(error => {
              setErrors(error.response.data);
              setSubmitting(false);
            });
        }}
        render={({ errors, isSubmitting }) => {
          return (
            <Form>
              <TextField
                label="First Name"
                name="name"
                type="text"
                placeholder="First Name"
                autoFocus={true}
              />
              <TextField
                label="Last Name"
                name="surname"
                type="text"
                placeholder="Last Name"
              />
              <TextField
                label="Company Name"
                name="company"
                type="text"
                placeholder="Company Name"
              />

              <TextField
                label="Email Address"
                name="email"
                type="email"
                placeholder="Please type your email"
              />
              <TextField label="Password" name="password" type="password" />
              <TextField
                label="Confirm Password"
                name="repeatPassword"
                type="password"
              />

              <p>
                By clicking “Sign up” I am agreeing to imby’s{" "}
                <a
                  href="https://www.inmybackyard.com.au/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </p>

              <SubmitButton
                className="form-button"
                value="Sign Up"
                submittingValue="Signing Up .."
              />

              <p style={{ textAlign: "left" }}>
                Already have an account? <Link to="/user/login">Log in</Link>
              </p>
            </Form>
          );
        }}
      />
    </>
  );
}

export default LoginForm;
