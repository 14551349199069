import React, { useEffect } from "react";
import "./logout.scss";
import { useAppState } from "@bluechilli/bcstatemachine";
import { navigate } from "@reach/router";

const LogOut = () => {
  const { logout } = useAppState("user")[1];
  useEffect(() => {
    logout()
      .then(_ => navigate("/user/login"))
      .catch(_ => navigate("/user/login"));
  }, []);

  return <div />;
};

export default LogOut;
