import React from "react";
import "./cancelsubscription.scss";
import { useAppState } from "../../../packages/bcstatemachine/src";

const CancelSubscription = () => {
  const modal = useAppState("modal");
  const { cancelAccount, getUserData } = useAppState("user")[1];
  return (
    <div className="cancel-subscription">
      <div>
        <h2>Are you sure you'd like to cancel your Imby subscription?</h2>

        <div className="mt-16">
          <button
            className="btn btn-warning"
            onClick={() => {
              cancelAccount().then(_ => {
                modal.close("cancel");
                getUserData();
              });
            }}
          >
            Yes
          </button>{" "}
          <button
            className="btn btn-secondary"
            onClick={() => {
              modal.close("cancel");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscription;
