import React, { useEffect, useState } from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import FullScreenSpinner from "@bluechilli/fullscreenspinner";
import { navigate } from "@reach/router";
import get from "lodash/get";
import "./imbywrapper.scss";

const queryString = require("query-string");

let previousState;

const ImbyWrapper = props => {
  const [
    { center, zoom, bounds },
    { setCenter, setZoom, setBounds, setSearchString }
  ] = useAppState("map");
  const [
    { selectedLocationData },
    { updateLocations, setHoverLocation, setSelectedLocationData }
  ] = useAppState("location");
  const [{ componentInRightPane }, { setComponentInRightPane }] = useAppState(
    "mapviewlayout"
  );

  const [
    { filterString, newFilterString },
    {
      setMapProperties,
      updateFilterString,
      updateAppList,
      updateTypeList,
      updateEstValues,
      updateStatusList
    }
  ] = useAppState("filterPersistence");
  const [{ isLoggedIn, isFinancial }] = useAppState("user");

  const [isReady, setIsReady] = useState(false);

  const populateStateFromQueryString = (backbuttonMode = false) => {
    const { query } = queryString.parseUrl(window.location.href);
    const { lat, lng, zoom, rc, rcp, s, loc } = query;

    if (backbuttonMode === false) {
      if (loc) {
        setSelectedLocationData({ id: loc });
      }

      if (lat && lng) {
        setCenter({ lat, lng });
      }

      if (zoom) {
        setZoom(zoom);
      }

      if (s) {
        setSearchString(s);
      }
    }

    if (rc) {
      setComponentInRightPane(rc, rcp);
    }

    if (rc === "property") {
      setHoverLocation(parseInt(rcp));
    }

    setIsReady(true);
  };

  // immediately redirect a user if user isn't financial
  useEffect(() => {
    if (isLoggedIn) {
      if (isFinancial === false) {
        navigate("/user/selectplan");
      }
    }
  }, [isFinancial, isLoggedIn]);

  // Write State from QUery string when App initialises
  useEffect(() => {
    populateStateFromQueryString();

    window.addEventListener(
      "popstate",
      function() {
        setTimeout(() => {
          populateStateFromQueryString(true);
        }, 100);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (newFilterString) {
      const fs = JSON.parse(newFilterString);

      const mapCenter = get(fs, "map.center");
      const mapZoom = get(fs, "map.zoom");
      const mapBounds = get(fs, "map.bounds");
      const estValue = get(fs, "estvalue");
      const statuses = get(fs, "status");
      const types = get(fs, "type");
      const apps = get(fs, "app");

      if (mapBounds) {
        setBounds(mapBounds);
      }

      if (mapCenter) {
        setCenter({ lat: mapCenter[0], lng: mapCenter[1] });
      }

      if (mapZoom) {
        setZoom(mapZoom);
      }

      if (apps) {
        let foo = {};
        apps.forEach(i => {
          foo[i] = true;
        });
        updateAppList(foo);
      } else {
        updateAppList({});
      }

      if (types) {
        let foo = {};
        types.forEach(i => {
          foo[i] = true;
        });
        updateTypeList(foo);
      } else {
        updateTypeList({});
      }

      if (estValue) {
        updateEstValues(estValue.low, estValue.high);
      } else {
        updateEstValues();
      }

      if (statuses) {
        let foo = {};
        statuses.forEach(i => {
          foo[i] = true;
        });
        updateStatusList(foo);
      } else {
        updateStatusList({});
      }

      updateFilterString();
    }
  }, [newFilterString]);

  // If the filterString change, do an api call
  useEffect(() => {
    updateLocations(filterString);
  }, [filterString]);

  useEffect(() => {
    setMapProperties({ center, zoom, bounds });
  }, [center, zoom, bounds]);

  // Check for specific state changes and push them as a query string
  useEffect(() => {
    const stateObj = {
      lat: center.lat,
      lng: center.lng,
      zoom,
      rc: componentInRightPane.component,
      rcp: componentInRightPane.params,
      loc: selectedLocationData ? parseInt(selectedLocationData.id) : null
    };
    const qs = queryString.stringify(stateObj);

    if (qualifiesForBackButton(previousState, stateObj)) {
      window.history.pushState(
        stateObj,
        "",
        window.location.pathname + "?" + qs
      );
    } else {
      window.history.replaceState(
        stateObj,
        "",
        window.location.pathname + "?" + qs
      );
    }
    previousState = stateObj;
  }, [
    center,
    zoom,
    JSON.stringify(componentInRightPane),
    JSON.stringify(selectedLocationData)
  ]);

  // App Hooks

  if (!isReady) return <FullScreenSpinner txt="Initiating Imby" />;

  return <>{props.children}</>;
};

const qualifiesForBackButton = (previousState, newState) => {
  const oldRc = get(previousState, "rc");
  const NewRc = get(newState, "rc");
  const oldRcp = get(previousState, "rcp");
  const newRcp = get(newState, "rcp");

  if (oldRc !== NewRc) return true;
  if (newRcp === undefined) return false;

  if (parseInt(oldRcp) !== parseInt(newRcp)) return true;
  return false;
};

export default ImbyWrapper;
