import React from "react";
import "./savesearchbutton.scss";
import { useAppState } from "@bluechilli/bcstatemachine";

const SaveSearchButton = ({ className, onClick = () => {} }) => {
  const cl = className || "savesearchbutton";
  const modal = useAppState("modal");
  const [{ isFinancial }] = useAppState("user");
  return (
    <span
      className={cl}
      onClick={e => {
        const whatModal = isFinancial ? "savedsearch" : "paywall";
        modal.open(whatModal);
        onClick(e);
      }}
    >
      save search
    </span>
  );
};

export default SaveSearchButton;
