import React from "react";
import { Form, Formik } from "formik";
import { RadioButton } from "@bluechilli/chilliforms";
import dateRanges from "../../constants/dateRanges";
import { useAppState } from "@bluechilli/bcstatemachine";
import PartialDateRange from "./PartialDateRange";
import Show from "@bluechilli/show";

const DDDateRange = () => {
  const [
    { dateRangeType, dateRangeHigh, dateRangeLow },
    { updateDateRange, updateAbsoluteDateRanges }
  ] = useAppState("filterPersistence");

  return (
    <>
      <Formik
        initialValues={{
          ddranges: dateRangeType
        }}
        render={vals => {
          return (
            <Form>
              <RadioButton
                className="ddcompact"
                options={dateRanges}
                name="ddranges"
                value={true}
                onChange={v => {
                  updateDateRange(v.value, v.absolute);
                }}
                insert={foo => {
                  return (
                    <Show
                      show={
                        foo.value === "custom" &&
                        vals.values.ddranges === "custom"
                      }
                      anim="fade"
                    >
                      <PartialDateRange
                        dateRangeHigh={dateRangeHigh}
                        dateRangeLow={dateRangeLow}
                        onChange={(s, e) => {
                          updateAbsoluteDateRanges(s, e);
                        }}
                      />
                    </Show>
                  );
                }}
              />
            </Form>
          );
        }}
      />
    </>
  );
};

export default DDDateRange;
