import { getLatLng } from "react-places-autocomplete";

function isInt(value) {
  return (
    !isNaN(value) &&
    (function(x) {
      return (x | 0) === x;
    })(parseFloat(value))
  );
}

const getSuggestedZoom = types => {
  const type = types[0];

  const mapTo = {
    premise: 18,
    street_address: 18,
    route: 17,
    establishment: 18,
    administrative_area_level_1: 7,
    colloquial_area: 13,
    postal_code: 14,
    locality: 15
  };

  if (mapTo[type]) return mapTo[type];

  console.log("Need handler for", type);
  return 12;
};

// Promise which returns filter-friendly value
const googleAddressSearch = address => {
  return getAddressWithBias(address, { country: "AU" }).then(async results => {
    const { lat, lng } = await getLatLng(results[0]);

    const suggestedZoom = getSuggestedZoom(results[0].types || []);

    const addressComponents = results[0].address_components;
    return {
      initialInput: address,
      formattedAddress: results[0].formatted_address,
      lat,
      lng,
      addressComponents,
      suggestedZoom
    };
  });
};

const getAddressWithBias = (address, componentRestrictions = {}) => {
  if (isInt(address)) {
    address = "postcode " + address;
  }
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise(function(resolve, reject) {
    geocoder.geocode({ address: address, componentRestrictions }, function(
      results,
      status
    ) {
      if (status !== OK) {
        reject(status);
      }

      if (results[0] === undefined) {
        reject("not found");
      } else {
        if (results[0].partial_match === true) {
          reject("not found");
        }
        resolve(results);
      }
    });
  });
};

export default googleAddressSearch;
