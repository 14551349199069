import React, { useEffect, useState } from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import FullScreenSpinner from "@bluechilli/fullscreenspinner";
const sesh = require("sessionstorage");

const AccountWrapper = props => {
  const [isReady, setIsReady] = useState(false);
  const [{ isLoggedIn }, { loginFromToken, getUserData }] = useAppState("user");

  useEffect(() => {
    const seshToken = sesh.getItem("token");

    if (seshToken) {
      loginFromToken(seshToken);
    }
  }, []);

  // Get user Data of all the things before
  // going into the app
  useEffect(() => {
    if (isLoggedIn === true) {
      setIsReady(false);

      getUserData().then(payload => {
        setIsReady(true);
      });
    } else {
      setIsReady(true);
    }
  }, [isLoggedIn]);

  if (!isReady) return <FullScreenSpinner txt="Fetching your Data" />;

  return <>{props.children}</>;
};

export default AccountWrapper;
