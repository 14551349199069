import React from "react";
import classnames from "classnames";
import "./imbylogo.scss";

const ImbyLogo = ({ pro = false, className = "" }) => {
  const classes = classnames("imbylogo", className);

  return (
    <h1 className={classes}>
      imby{pro === true && <span className="pro">pro</span>}
    </h1>
  );
};

export default ImbyLogo;
