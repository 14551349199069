import React from "react";

import {
  faLayerGroup,
  faDrawPolygon,
  faMap,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

import FilterDropDown from "../FilterDropDown/FilterDropDown";
import LayerTypes from "./LayerTypes";

import "./map-overlay-bar.scss";
import { useAppState } from "@bluechilli/bcstatemachine";
import MapViewTypes from "./MapViewTypes";
import LotTypes from "./LotTypes";
import MapDownload from "./MapDownload";
import {Link} from "@reach/router";
import ImbyLogo from "../ImbyLogo/ImbyLogo";


export default function MapOverlayBar() {
  const [{ isFinancial }] = useAppState("user");
  const modal = useAppState("modal");

  return (
    <div className="map-overlay-bar">

        <FilterDropDown label="map view" icon={faMap}>
            <MapViewTypes />
        </FilterDropDown>

        <FilterDropDown
            label="planning layers"
            icon={faLayerGroup}
            disabled={!isFinancial}
            onClick={e => {
              if (!isFinancial) modal.open("paywall");
            }}
            >
            <LayerTypes />
        </FilterDropDown>

        <FilterDropDown
            label="boundaries"
            icon={faDrawPolygon}
            disabled={!isFinancial}
            onClick={e => {
            if (!isFinancial) modal.open("paywall");
            }}
        >
            <LotTypes />
        </FilterDropDown>

{/*
        <FilterDropDown
            label="download"
            icon={faDownload}
            disabled={!isFinancial}
            onClick={e => {
                if (!isFinancial) modal.open("paywall");
            }}
        >
            <MapDownload />
        </FilterDropDown>
*/}

    </div>
  );
}
