import { useState, useEffect } from "react";
import moment from "moment";

const useFilterPersistence = () => {
  const [apps, setApps] = useState({});

  const [types, setTypes] = useState({});
  const [statuses, setStatuses] = useState({});

  const [estValueLow, _setestValueHLow] = useState();
  const [estValueHigh, _setestValueHigh] = useState();
  const [dateRangeHigh, _setDateRangeHigh] = useState(new Date());
  const [dateRangeLow, _setDateRangeLow] = useState(new Date());
  const [dateRangeAbsolute, setDateRangeAbsolute] = useState(false);
  const [dateRangeType, setDateRangeType] = useState("last 365 days");
  const [mapProperties, setMapProperties] = useState();
  const [filterString, setFilterString] = useState();
  const [newFilterString, updateFilterString] = useState();
  const [effectedFilters, setEffectedFilters] = useState({ type: 0 });

  useEffect(() => {
    encodeFilterString();
  }, [
    apps,
    types,
    statuses,
    dateRangeType,
    dateRangeHigh,
    dateRangeLow,
    estValueHigh,
    estValueLow,
    JSON.stringify(mapProperties)
  ]);

  const encodeFilterString = () => {
    const encodedApps = encodeTypes(apps);

    const encodedTypes = encodeTypes(types);

    const encodedEstimatedValues = encodeEstimatedValues(
      estValueLow,
      estValueHigh
    );

    const encodedDateRange = encodeDateRange(
      dateRangeType,
      dateRangeAbsolute,
      dateRangeHigh,
      dateRangeLow
    );

    const encodedStatus = encodeStatuses(statuses);

    const encodedMapProperties = encodeMapProperties(mapProperties);

    const _filterString = {
      app: encodedApps,
      type: encodedTypes,
      estvalue: encodedEstimatedValues,
      date: encodedDateRange,
      status: encodedStatus,
      map: encodedMapProperties
    };

    Object.keys(_filterString).forEach(test => {
      if (
        _filterString[test] === [] ||
        !Object.keys(_filterString[test]).length ||
        _filterString[test] === undefined
      ) {
        delete _filterString[test];
      }
    });

    //console.log(JSON.stringify(_filterString));

    setFilterString(JSON.stringify(_filterString));
    updateEffectedFilters();
  };

  const updateAppList = values => {
    setApps(values);
  };

  const updateTypeList = values => {
    setTypes(values);
  };

  const updateStatusList = values => {
    setStatuses(values);
  };

  const updateEstValues = (low = 0, high = 10000000) => {
    _setestValueHLow(parseInt(low) !== 0 ? low : undefined);
    _setestValueHigh(parseInt(high) < 10000000 ? high : undefined);
  };

  const updateDateRange = (type, isAbsolute) => {
    setDateRangeType(type);
    setDateRangeAbsolute(isAbsolute);
  };

  const updateAbsoluteDateRanges = (low, high) => {
    _setDateRangeHigh(high);
    _setDateRangeLow(low);
  };

  const updateEffectedFilters = () => {
    const { low, high } = encodeEstimatedValues(estValueLow, estValueHigh);

    const foo = {
      app: encodeApps(apps).length,
      type: encodeTypes(types).length,
      value: low !== undefined || high !== undefined,
      daterange: dateRangeType !== "last 365 days",
      status: encodeStatuses(statuses).length
    };

    setEffectedFilters(foo);
  };

  return [
    {
      apps,
      types,
      statuses,
      estValueLow,
      estValueHigh,
      dateRangeType,
      dateRangeAbsolute,
      dateRangeHigh,
      dateRangeLow,
      filterString,
      newFilterString,
      effectedFilters
    },
    {
      updateAppList,
      updateTypeList,
      updateStatusList,
      updateEstValues,
      updateDateRange,
      updateAbsoluteDateRanges,
      setMapProperties,
      updateFilterString
    }
  ];
};

const encodeApps = apps => {
  return Object.entries(apps)
      .map(t => {
        if (t[1] === true) return parseInt(t[0]);
        else return undefined;
      })
      .filter(t => t !== undefined);
};

const encodeTypes = types => {
  return Object.entries(types)
    .map(t => {
      if (t[1] === true) return parseInt(t[0]);
      else return undefined;
    })
    .filter(t => t !== undefined);
};

const encodeEstimatedValues = (eslow, eshigh) => {
  if (!eslow && !eshigh) return {};

  return {
    low: eslow ? eslow : 0,
    high: eshigh ? eshigh : 1000000000 // Projects up to $1B when unrestricted
  };
};

const encodeDateRange = (
  dateRangeType,
  dateRangeAbsolute,
  dateRangeHigh,
  dateRangeLow
) => {
  if (dateRangeType === "custom") {
    return {
      type: "custom",
      start: moment(dateRangeLow).format("YYYY-MM-DD"),
      end: moment(dateRangeHigh).format("YYYY-MM-DD")
    };
  } else if (dateRangeType === "all") {
    return {};
  } else {
    return {
      type: dateRangeType
    };
  }
};

const encodeStatuses = status => {
  return Object.entries(status)
    .map(t => {
      if (t[1] === true) {
        return parseInt(t[0]);
      } else return undefined;
    })
    .filter(t => t !== undefined);
};

const encodeMapProperties = mapProps => {
  if (mapProps === undefined || mapProps.bounds === undefined) return {};

  const bounds = mapProps.bounds.map(n => {
    return parseFloat(n.toFixed(5));
  });

  const center = [mapProps.center.lat, mapProps.center.lng].map(n => {
    return parseFloat(n.toFixed(5));
  });

  return {
    bounds,
    center,
    zoom: mapProps.zoom
  };
};

export default useFilterPersistence;
