import { useState, useRef } from "react";
import debounce from "lodash/debounce";
import { apiGetMarkersQuery } from "../api/map";

import { apiGetLocation } from "../api/property";

const useLocation = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [hoverLocation, setHoverLocation] = useState();
  const [selectedLocationData, setSelectedLocationData] = useState({});
  const [setFilterString] = useState();

  const [locations, setLocations] = useState({
    entries: [],
    applications: 0,
    properties: 0
  });

  const getLocationDataFromLocationPool = (id, _locs) => {
    return _locs.entries.find(location => {
      return location.location_id === id;
    });
  };

  // Features object
  const _updateFeatures = useRef(
    debounce(filterString => {
      if (filterString !== undefined && filterString !== "{}") {
        setIsFetching(true);
        apiGetMarkersQuery(filterString).then(payload => {
          setLocations(normaliseLocationData(payload.features));
          setIsFetching(false);
        });
      }
    }, 400)
  );

  const getLocation = id => {
    return apiGetLocation(id);
  };

  const updateLocations = bounds => _updateFeatures.current(bounds);

  return [
    {
      isFetching,
      locations,
      hoverLocation,
      selectedLocationData
    },
    {
      setHoverLocation,
      updateLocations,
      getLocation,
      setSelectedLocationData,
      getLocationDataFromLocationPool,
      setFilterString
    }
  ];
};

// Turns API data into something more manageable by the view
const normaliseLocationData = features => {
  let arr = [];
  let overAllApplicationCount = 0;
  features.forEach(feature => {
    const k = feature.lat + "." + feature.lng;

    if (arr[k] === undefined) {
      arr[k] = [feature];
    } else {
      arr[k].push(feature);
    }
  });

  const entries = Object.entries(arr).map(val => {
    const { lat, lng, location, location_id } = val[1][0];
    overAllApplicationCount = overAllApplicationCount + val[1].length;

    return {
      lat,
      lng,
      location,
      location_id,
      data: val[1],
      applicationCount: val[1].length
    };
  });

  return {
    entries,
    properties: entries.length,
    applications: overAllApplicationCount
  };
};

export default useLocation;
