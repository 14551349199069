import * as Yup from "yup";

const mydetailsFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  name: Yup.string().required("First Name is required"),
  surname: Yup.string().required("Last Name is required"),
  company: Yup.string(),
  mobile: Yup.string()
});

export default mydetailsFormValidationSchema;
