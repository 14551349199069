import React, { useState, useEffect } from "react";
import "./show.scss";

const Show = ({ show = true, children, anim = false, ...rest }) => {
  if (anim === false) {
    return <ShowWithoutAnim show={show} children={children} {...rest} />;
  } else {
    return (
      <ShowWithAnim show={show} anim={anim} children={children} {...rest} />
    );
  }
};

const ShowWithAnim = ({
  show = true,
  children,
  anim = "fade",
  animSpeed = 300,
  absolute = false
}) => {
  const [isShow, setIsShow] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [isAppearing, setIsAppearing] = useState(false);

  const transition = {
    transition: animSpeed + "ms all",
    position: absolute ? "absolute" : "initial"
  };

  useEffect(() => {
    // Hiding
    if (show === false && isShow === true) {
      setIsLeaving(true);
      setIsShow(false);
      setTimeout(() => setIsLeaving(false), animSpeed);
    }

    // Showing
    if (show === true && isShow === false) {
      setIsAppearing(true);
      setTimeout(() => {
        setIsShow(true);
        setIsAppearing(false);
      }, 100);
    }
  }, [show]);

  useEffect(() => {
    if (show === true) {
      setIsAppearing(true);
      setTimeout(() => {
        setIsShow(true);
        setIsAppearing(false);
      }, 70);
    }
  }, []);

  if (isShow === false && isLeaving === false && isAppearing === false) {
    return null;
  }

  const trueCss = `bcshow-${anim}-true`;
  const falseCss = `bcshow-${anim}-false`;

  return (
    <span
      style={transition}
      className={`_show-base ${isShow ? trueCss : falseCss}`}
    >
      {children}
    </span>
  );
};

const ShowWithoutAnim = ({ show = true, children }) => {
  if (show === false) return null;
  return <>{children}</>;
};

export default Show;
