import React from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import { Link, navigate } from "@reach/router";
import ImbyLogo from "../ImbyLogo/ImbyLogo.jsx";

import "./paywall.scss";

const PayWall = () => {
  const modal = useAppState("modal");

  return (
    <div className="paywall">
      <div className="paywall-header">
        <ImbyLogo />
      </div>

      <div className="paywall-body">
        <p className="mt-32">This is a subscription only feature</p>
        <h2 className="mt-16">access advanced tools and data</h2>

        <button
          onClick={() => {
            navigate("/user/selectplan");
            modal.close("paywall");
          }}
        >
          subscribe
        </button>

        <p className="mt-16">
          Already subscribed?
          <br />
          <Link
            to="user/login"
            onClick={() => {
              modal.close("paywall");
            }}
          >
            Login to your account
          </Link>
        </p>
      </div>
    </div>
  );
};

export default PayWall;
