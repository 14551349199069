const planningLayers = [
  {
    label: "None",
    value: ""
  },
  {
    label: "Heritage",
    value: "0"
  },
  {
    label: "Floor Space Ratio",
    value: "1"
  },
  {
    label: "Land Zoning",
    value: "2"
  },
  {
    label: "Land Reservation Acquisition",
    value: "3"
  },
  {
    label: "Lot SIze",
    value: "4"
  },
  {
    label: "Height of Building",
    value: "5"
  },
  {
    label: "Land Application",
    value: "6"
  }
];

export default planningLayers;
