import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
  //withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  },
  responseType: "json"
});

export default axiosInstance;
