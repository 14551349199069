import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Col, Container, Row } from "react-grid-system";
import get from "lodash/get";
import { TextField, SubmitButton, FormError } from "@bluechilli/chilliforms";
import { useAppState } from "@bluechilli/bcstatemachine";
import mydetailsFormValidationSchema from "./mydetailsFormValidationSchema";
import useTimer from "@bluechilli/usetimer";
import Show from "@bluechilli/show";

const MyDetails = () => {
  const { getUserData, updateUser } = useAppState("user")[1];
  const [userData, setUserData] = useState();
  const [isReady, setIsReady] = useState(false);

  const { timerOn, startTimer } = useTimer();

  useEffect(() => {
    getUserData().then(p => {
      setUserData(get(p, "profile.data"));
      setIsReady(true);
    });
  }, []);

  if (!isReady) return null;

  return (
    <Formik
      isInitialValid={true}
      validationSchema={mydetailsFormValidationSchema}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        const { name, surname, email, company, mobile } = values;
        updateUser(name, surname, email, company, mobile)
          .then(p => {
            setSubmitting(false);
            startTimer(3000);
          })
          .catch(error => {
            setErrors(error.response.data);
            setSubmitting(false);
          });
      }}
      initialValues={userData}
      render={({ errors, isSubmitting }) => {
        return (
          <Form>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <h4>My Details</h4>
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <TextField
                    label="First Name"
                    name="name"
                    type="text"
                    placeholder="Please enter your first name"
                    autoFocus={true}
                  />
                </Col>

                <Col xl={6}>
                  <TextField
                    label="Last Name"
                    name="surname"
                    type="text"
                    placeholder="Please enter your last name"
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <TextField
                    label="Email address"
                    name="email"
                    type="email"
                    placeholder="Please enter your email address"
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <TextField
                    label="Company Name"
                    name="company"
                    type="text"
                    placeholder="Please enter your company name"
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <TextField
                    label="Mobile"
                    name="mobile"
                    type="text"
                    placeholder="Please enter your mobile number"
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <FormError />

                  <SubmitButton
                    className="form-button"
                    value="update"
                    submittingValue="updating .."
                  />
                  <Show show={timerOn} anim="fade">
                    Your details have been updated
                  </Show>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    />
  );
};

export default MyDetails;
