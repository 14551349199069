import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const PartialDateRate = ({
  dateRangeHigh = new Date(),
  dateRangeLow = new Date(),
  onChange = () => {}
}) => {
  const [startDate, setStartDate] = useState(dateRangeLow);
  const [endDate, setEndDate] = useState(dateRangeHigh);

  const onChangeHandler = s => {
    setStartDate(s.range1.startDate);
    setEndDate(s.range1.endDate);
    onChange(s.range1.startDate, s.range1.endDate);
  };

  return (
    <div className="partial-date-range">
      <DateRange
        ranges={[
          {
            startDate,
            endDate
          }
        ]}
        moveRangeOnFirstSelection={false}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default PartialDateRate;
