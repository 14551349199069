import React from "react";
import "./login.scss";
import { Col, Container, Row } from "react-grid-system";
import Content from "../../Content/Content.jsx";
import LoginForm from "../Forms/LoginForm/LoginForm";

const Login = () => {
  return (
    <Content noSearch={true}>
      <Container fluid className="narrow-form">
        <Row className="pt-32" justify="center">
          <Col md={12} align="center">
            <h1 className="mb-32">log in</h1>

            <h3 className="mb-32">access your account</h3>

            <LoginForm />
          </Col>
        </Row>
      </Container>
    </Content>
  );
};

export default Login;
