import React, { useRef, useEffect, useState } from "react";
import { Map, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./viewmap.scss";
import { imageMapLayer } from "../../helpers/esri-leaflet-debug";
import FullHeight from "@bluechilli/fullheight";
import { useAppState } from "@bluechilli/bcstatemachine";

import L from "leaflet";
import SpinnerOnMap from "../SpinnerOnMap/SpinnerOnMap";
import { marker1, marker2, search } from "../../assets/markers";
import mapViews from "../../constants/mapViews";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const ViewMap = () => {
  const [openedOnce, setOpenedOnce] = useState(false);

  const [
    {
      center,
      zoom,
      bounds,
      searchMarker,
      selectedLayerNumber,
      currentImageMapLayer,
      currentLotMapLayer,
      mapViewId,
      selectedLot
    },
    { syncMapStateWithLeafletElement, setImageMapLayer, setCurrentLotMapLayer }
  ] = useAppState("map");

  const [
    { isFetching, locations, hoverLocation },
    { setHoverLocation }
  ] = useAppState("location");

  const [
    { screenSize, rightMenuIsOpen },
    { setComponentInRightPane, setRightMenu }
  ] = useAppState("mapviewlayout");

  const isMobile = screenSize === "small";

  const mapRef = useRef();

  // Every time there's a map change,
  const mapUpdateHandler = o => {
    const { leafletElement } = mapRef.current;
    syncMapStateWithLeafletElement(leafletElement);
  };

  // This function fires after a successful fetch
  useEffect(() => {
    if (isMobile) return;

    const hasEntries = locations.entries.length > 0;

    // Make sure it's closed when first being initialised
    if (openedOnce === false && !hasEntries) {
      setRightMenu(false);
    }

    // Open it up
    if (rightMenuIsOpen === false && hasEntries) {
      setRightMenu(true);
      setOpenedOnce(true);
    }

    if (!hasEntries && rightMenuIsOpen === false && !isFetching) {
      setRightMenu(true);
      setOpenedOnce(true);
    }
  }, [isFetching, locations.entries.length]);

  // listen for changes in selected layer
  useEffect(() => {
    if (mapRef && mapRef.current) {
      const { leafletElement } = mapRef.current;
      if (currentImageMapLayer) {
        leafletElement.removeLayer(currentImageMapLayer);
      }
      const mapLayer = imageMapLayer({
        url:
          "https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/Planning/EPI_Primary_Planning_Layers/MapServer",
        f: "image",
        dpi: "96",
        opacity: 0.5,
        layers: `show:${selectedLayerNumber}`
      });

      setImageMapLayer(mapLayer);
    }
  }, [selectedLayerNumber]);

  useEffect(() => {
    if (mapRef && mapRef.current) {
      const { leafletElement } = mapRef.current;
      if (currentLotMapLayer) {
        leafletElement.removeLayer(currentLotMapLayer);
      }

      const foo = imageMapLayer({
        url: selectedLot,
        f: "image",
        dpi: "96"
      });

      setCurrentLotMapLayer(foo);
    }
  }, [selectedLot]);

  // render current selected map layer
  useEffect(() => {
    if (mapRef && mapRef.current) {
      const { leafletElement } = mapRef.current;
      currentImageMapLayer.addTo(leafletElement);
    }
  }, [currentImageMapLayer]);

  // render current lot map
  useEffect(() => {
    if (mapRef && mapRef.current) {
      const { leafletElement } = mapRef.current;
      currentLotMapLayer.addTo(leafletElement);
    }
  }, [currentLotMapLayer]);

  return (
    <FullHeight>
      {h => (
        <div className="viewmap">
          {bounds && isFetching && <SpinnerOnMap />}

          <Map
            center={center}
            zoom={zoom}
            minZoom={12}
            maxZoom={18}
            ref={mapRef}
            style={{ height: h }}
            onViewportChanged={obj => mapUpdateHandler(obj)}
            whenReady={obj => {
              // Artifically run map handler
              // to account for when markers don't show
              setTimeout(() => {
                mapUpdateHandler(obj);
              }, 2000);
              mapUpdateHandler(obj);
            }}
          >
            {searchMarker && (
              <Marker
                icon={search}
                position={[searchMarker.lat, searchMarker.lng]}
              />
            )}

            {locations.entries.map((feature, k) => {
              const icon =
                hoverLocation === feature.location_id ? marker2 : marker1;

              return (
                <Marker
                  key={k}
                  icon={icon}
                  position={[feature.lat, feature.lng]}
                  onClick={() => {
                    setComponentInRightPane("property", feature.location_id);
                    setHoverLocation(feature.location_id);
                  }}
                />
              );
            })}

            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url={mapViews[mapViewId]}
            />
          </Map>
        </div>
      )}
    </FullHeight>
  );
};

export default ViewMap;
