import React, { useEffect, useRef, useState } from "react";
import "./filterdropdown.scss";
import Show from "@bluechilli/show";
import DropDownContext from "./DropDownContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FilterDropDown = ({
  label,
  children,
  icon,
  highlight = false,
  onClick = () => {},
  disabled = false
}) => {
  const [isOpened, _setIsOpened] = useState(false);

  const node = useRef();

  const handleClick = e => {
    if (!node.current.contains(e.target)) {
      setIsOpened(false);
    }
  };

  const setIsOpened = v => {
    _setIsOpened(v);
  };

  useEffect(() => {
    if (isOpened === true) {
      document.addEventListener("mousedown", handleClick);

      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }
  }, [isOpened]);

  const fddClassName = isOpened
    ? "filterdropdown fdd-selected"
    : "filterdropdown";

  const hightlightClass = highlight && !isOpened ? "fdd-highlight" : "";

  return (
    <DropDownContext.Provider value={{ isOpened, setIsOpened }}>
      <div
        ref={node}
        className={`${fddClassName} ${hightlightClass}`}
        onClick={e => {
          onClick(e);
          if (disabled === false) setIsOpened(true);
        }}
      >
        {icon && (
          <div className="fdd-icon">
            <FontAwesomeIcon icon={icon} />
          </div>
        )}

        <div className="fdd-label">{label}</div>
        <Show show={isOpened} anim="menu" animSpeed={150}>
          <div className="fdd-content">{children}</div>
        </Show>
      </div>
    </DropDownContext.Provider>
  );
};

export default FilterDropDown;
