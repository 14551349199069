import React from "react";
import "./savesearchmodal.scss";
import { useAppState } from "@bluechilli/bcstatemachine";
import { Form, Formik } from "formik";

import {
  CheckboxField,
  SubmitButton,
  TextField
} from "@bluechilli/chilliforms";
import savedSearchvalidationschema from "./savedSearchvalidationschema";

const SaveSearchModal = () => {
  const [{ filterString }] = useAppState("filterPersistence");

  const { setComponentInRightPane } = useAppState("mapviewlayout")[1];

  const { saveSearch } = useAppState("user")[1];

  const modal = useAppState("modal");

  return (
    <div className="savesearchmodal">
      <div>
        <Formik
          validationSchema={savedSearchvalidationschema}
          initialValues={{
            name: ""
          }}
          onSubmit={values => {
            const notify = values.notify !== undefined;

            saveSearch(values.name, filterString, notify).then(p => {
              modal.close("savedsearch");
              setComponentInRightPane("savedsearches");
            });
          }}
          render={({ errors, isSubmitting }) => {
            return (
              <Form>
                <TextField
                  label="Search Name"
                  name="name"
                  type="text"
                  autoFocus={true}
                />

                <CheckboxField
                  parent="notify"
                  value={true}
                  label="Send any updates to this search over email"
                />
                <SubmitButton
                  className="form-button"
                  value="Save"
                  submittingValue="Saving .."
                />
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
};

export default SaveSearchModal;
