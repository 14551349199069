import React from "react";
import { Redirect, Router } from "@reach/router";
import Fourohfour from "@bluechilli/fourohfour";
import env from "@bluechilli/env";
import { AppStateWrapper } from "@bluechilli/bcstatemachine";
import "./assets/styles/main.scss";
import Home from "./components/Home/Home";
import useMapViewLayout from "./state/useMapViewLayout";
import useMap from "./state/useMap";
import ImbyWrapper from "./components/ImbyWrapper/ImbyWrapper";
import Account from "./components/Account/AccountRoutes";
import useUser from "./state/useUser";
import useProperty from "./state/useProperty";
import useApplication from "./state/useApplication";
import useLocation from "./state/useLocation";
import AxiosMiddleareWrapper from "./components/AxiosMiddleareWrapper/AxiosMiddleareWrapper";
import AccountWrapper from "./components/AccountWrapper/AccountWrapper";
import useFilterPersistence from "./state/useFilterPersistence";
import ScriptLoaderWrapper from "@bluechilli/scriptloaderwrapper";
import { useModal } from "@bluechilli/bcmodal";
import Contact from "./components/Contact/Contact";

const containers = {
  mapviewlayout: useMapViewLayout,
  map: useMap,
  location: useLocation,
  property: useProperty,
  application: useApplication,
  user: useUser,
  filterPersistence: useFilterPersistence,
  modal: useModal
};

const gmapScript = `https://maps.googleapis.com/maps/api/js?key=${env(
  "REACT_APP_GMAP_KEY"
)}&libraries=places`;

const App = () => {
  return (
    <AppStateWrapper
      containers={containers}
      wrappers={[
        <AccountWrapper />,
        <ImbyWrapper />,
        <AxiosMiddleareWrapper />,
        <ScriptLoaderWrapper
          scripts={[gmapScript, "https://js.stripe.com/v3/"]}
        />
      ]}
    >
      <Router>
        <Fourohfour default />
        <Home path="/mapview/*" />
        <Home path="/" />
        <Account path="/user/*" />
        <Contact path="contact" />
        <Redirect from="index.html" to="/" />
      </Router>
    </AppStateWrapper>
  );
};

export default App;
