import React from "react";
import { Link } from "@reach/router";
const classnames = require("classnames");

const ReachRouterLink = ({
  activeClassName = "active",
  className,
  ...rest
}) => {
  return (
    <Link
      {...rest}
      getProps={({ isCurrent }) => {
        return {
          className: isCurrent
            ? classnames(activeClassName, className)
            : className
        };
      }}
    />
  );
};

export default ReachRouterLink;
