import React from "react";
import { connect } from "formik";

import { ElementWrap, InputWrap } from "../../Scaffolding/Scaffolding";

function NextButton(props) {
  const { name, className = "", value = "Next", onClick } = props;

  const { isSubmitting, isValid } = props.formik;

  return (
    <ElementWrap name={name}>
      <InputWrap name={name}>
        <button
          className={className}
          disabled={isSubmitting || !isValid}
          onClick={onClick}
        >
          {value}
        </button>
      </InputWrap>
    </ElementWrap>
  );
}

export default connect(NextButton);
