const mapLots = [
  {
    label: "None",
    value: ""
  },
  {
    label: "Properties (CADASTRE)",
    value:
      "http://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Cadastre/MapServer"
  },
  {
    label: "Postal Areas",
    value:
        "https://geo.abs.gov.au/arcgis/rest/services/ASGS2016/POA/MapServer"
  },
  {
    label: "Local Authorities",
    value:
        "https://geo.abs.gov.au/arcgis/rest/services/ASGS2019/LGA/MapServer"
  }
];

export default mapLots;
