import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Col, Container, Row } from "react-grid-system";

import { useAppState } from "@bluechilli/bcstatemachine";
import CheckBoxFieldArray from "@bluechilli/chilliforms/src/components/CheckboxFieldArray/CheckboxFieldArray";
import Show from "@bluechilli/show";

const Login = () => {
  const { getSearches, updateSearch } = useAppState("user")[1];
  const [savedSearches, setSavedSearches] = useState([]);
  const [ssValues, setSsValues] = useState({});
  const [isReady, setIsReady] = useState(false);

  const loadSearches = () => {
    setSavedSearches([]);
    getSearches().then(payload => {
      const foo = payload.features
        .map(p => {
          if (p.filter) {
            return {
              label: p.name,
              value: p.id,
              checked: p.notify,
              filter: p.filter
            };
          } else return null;
        })
        .filter(p => p);

      let t = {};

      foo.forEach(p => {
        if (p.checked) {
          t[p.value] = true;
        }
      });
      setSsValues(t);

      setSavedSearches(foo);
      setIsReady(true);
    });
  };

  const getById = id => {
    return savedSearches.find(p => p.value === id);
  };

  useEffect(() => {
    loadSearches();
  }, []);

  if (!isReady) return null;

  return (
    <Formik
      initialValues={{
        ss: ssValues
      }}
      render={_ => {
        return (
          <Form>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <h4>Notification Settings</h4>
                </Col>
              </Row>

              <Row>
                <Col xl={6}>
                  <Show show={savedSearches.length === 0}>
                    No Saved Searches
                  </Show>

                  <CheckBoxFieldArray
                    data={savedSearches}
                    name="ss"
                    onClick={(name, value, label, id) => {
                      const filter = getById(id).filter;

                      updateSearch(id, label, filter, !value);
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    />
  );
};

export default Login;
