import React from "react";
import "./filterbar.scss";
import { Col, Container, Row, Hidden, Visible } from "react-grid-system";
import FilterDropDown from "../FilterDropDown/FilterDropDown";
import DDFilterTypes from "./DDFilterTypes";
import DDEstValue from "./DDEstValue";
import DDDateRange from "./DDDateRange";
import DDStatus from "./DDStatus";
import { useAppState } from "@bluechilli/bcstatemachine";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SaveSearchButton from "../SaveSearchButton/SaveSearchButton";
import DDSavedSearch from "./DDSavedSearch";
import DDFilterApps from "./DDFilterApps";

const FilterBar = () => {
  const [{ componentInRightPane }, { setComponentInRightPane }] = useAppState(
    "mapviewlayout"
  );
  const [{ isFinancial }] = useAppState("user");
  const [{ effectedFilters }] = useAppState("filterPersistence");

  const modal = useAppState("modal");

  const savedSearchesSelected =
    componentInRightPane.component === "savedsearches";

  const appEffected = effectedFilters.app > 0;
  const typeEffected = effectedFilters.type > 0;
  const valueEffected = effectedFilters.value;
  const dateEffected = effectedFilters.daterange;
  const statusEffected = effectedFilters.status > 0;

  const typeLabel = typeEffected ? `building class (${effectedFilters.type})` : "building class";

  const appLabel = appEffected ? `application types (${effectedFilters.app})` : "application types";


  const valueLabel = valueEffected ? "estimated value *" : "estimated value";

  const dateRangeLabel = dateEffected ? `date range *` : "date range";

  const statusLabel = statusEffected ? `approval status (${effectedFilters.status})` : "approval status";

  return (
    <Container className="filterbar" fluid>
      <Row>
        <Col lg={8} xs={12} sm={6}>
          <div
            style={{
              display: "inline-flex"
            }}
            onClick={e => {
              if (!isFinancial) modal.open("paywall");
            }}
          >
            <FilterDropDown
                highlight={appEffected}
                label={appLabel}
                icon={faFile}
                disabled={!isFinancial}
            >
              <DDFilterApps />
            </FilterDropDown>

            <FilterDropDown
              highlight={typeEffected}
              label={typeLabel}
              icon={faBuilding}
              disabled={!isFinancial}
            >
              <DDFilterTypes />
            </FilterDropDown>

            <FilterDropDown
              highlight={valueEffected}
              label={valueLabel}
              icon={faDollarSign}
              disabled={!isFinancial}
            >
              <DDEstValue />
            </FilterDropDown>

            <FilterDropDown
              highlight={dateEffected}
              label={dateRangeLabel}
              icon={faCalendarAlt}
              disabled={!isFinancial}
            >
              <DDDateRange />
            </FilterDropDown>

            <FilterDropDown
              highlight={statusEffected}
              label={statusLabel}
              icon={faCheckCircle}
              disabled={!isFinancial}
            >
              <DDStatus />
            </FilterDropDown>

            <Visible xs>
              <FilterDropDown
                label="saved"
                className="mobile-only"
                disabled={!isFinancial}
              >
                <DDSavedSearch />
              </FilterDropDown>
            </Visible>
          </div>
        </Col>
        <Col lg={4} xs={12} sm={6} hidden="xs">
          <Hidden xs>
            <ul className="filterbar-nav">
              <li className="save-this-search">
                <span className="fbar-icon">
                  <FontAwesomeIcon icon={faPlus} />
                </span>

                <SaveSearchButton className="in-filterbar" />
              </li>
              <li
                className="saved-searches"
                onClick={() => {
                  if (!isFinancial) {
                    modal.open("paywall");
                    return;
                  }

                  const targetComponent = !savedSearchesSelected
                    ? "savedsearches"
                    : "propertylist";

                  setComponentInRightPane(targetComponent);
                }}
              >
                <span className="fbar-icon">
                  <FontAwesomeIcon icon={faBookmark} />
                </span>
                saved searches
              </li>
            </ul>
          </Hidden>
        </Col>
      </Row>
    </Container>
  );
};

export default FilterBar;
