import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { RadioButton } from "@bluechilli/chilliforms";
import { useAppState } from "@bluechilli/bcstatemachine";
import DropDownContext from "../FilterDropDown/DropDownContext";
import mapLots from "../../constants/mapLots";

const LayerTypes = () => {
  const [{ selectedLot }, { setSelectedLot }] = useAppState("map");

  const { setIsOpened } = useContext(DropDownContext);

  const onChange = values => {
    setSelectedLot(values.lotLayers);
    return {};
  };

  return (
    <>
      <Formik
        initialValues={{
          lotLayers: selectedLot || ""
        }}
        validate={onChange}
        render={() => {
          return (
            <Form>
              <RadioButton
                className="ddcompact"
                options={mapLots}
                name="lotLayers"
                value={true}
                onChange={() => {
                  setIsOpened(false);
                }}
              />
            </Form>
          );
        }}
      />
    </>
  );
};

export default LayerTypes;
