const mapViews = {
  default: " https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png",
  light: " https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
  dark: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
  satellite: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
  // default: "https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png",
  //   "color street": "http://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
};

export default mapViews;
