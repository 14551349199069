import React from "react";
import "./fullscreenfatalerror.scss";

const FullScreenFatalError = ({ children }) => (
  <div className="fs-error-container-bg">
    <div>{children}</div>
  </div>
);

export default FullScreenFatalError;
