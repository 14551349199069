import React from "react";
import { Form, Formik } from "formik";
import { useAppState } from "@bluechilli/bcstatemachine";

import {
  TextField,
  SubmitButton,
  TextareaField
} from "@bluechilli/chilliforms";
import { navigate } from "@reach/router";

import contactFormValidationSchema from "./contactFormValidationSchema";
import get from "lodash/get";
import { apiContact } from "../../api/contact";

const ContactForm = () => {
  const [{ userData }] = useAppState("user");

  const name = get(userData, "profile.data.name", "");
  const surname = get(userData, "profile.data.surname", "");
  const email = get(userData, "profile.data.email", "");

  return (
    <>
      <Formik
        validationSchema={contactFormValidationSchema}
        initialValues={{
          name: `${name} ${surname}`,
          email
        }}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          const { email, name, subject, message } = values;

          apiContact(name, email, subject, message)
            .then(_ => {
              navigate("/");
            })
            .catch(error => {
              setErrors(error.response.data);
              setSubmitting(false);
            });
        }}
        render={({ errors, isSubmitting }) => {
          return (
            <Form>
              <TextField
                label="Name"
                name="name"
                type="text"
                placeholder="Your Name"
                autoFocus={true}
              />

              <TextField
                label="Email Address"
                name="email"
                type="email"
                placeholder="Please type your email"
              />
              <TextField
                label="Subject"
                name="subject"
                type="text"
                placeholder="Please type in a subject"
              />

              <TextareaField
                label="Message"
                name="message"
                placeholder="Your Message"
              />

              <SubmitButton
                className="form-button"
                value="Send"
                submittingValue="Sending .."
              />
            </Form>
          );
        }}
      />
    </>
  );
};

export default ContactForm;
