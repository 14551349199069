import React, { Fragment } from "react";
import { connect, Field } from "formik";

import { ErrorContainer } from "../../Scaffolding/Scaffolding";

const HiddenField = props => {
  const { name, ...rest } = props;

  return (
    <Fragment>
      <Field id={name} name={name} type="hidden" {...rest} />
      <ErrorContainer name={name} />
    </Fragment>
  );
};

export default connect(HiddenField);
