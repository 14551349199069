import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    let { token } = await this.props.stripe.createToken({ name: "Name" });
    this.props.onSubmit(token);
  }

  render() {
    return (
      <div className="checkout">
        <CardElement hidePostalCode={true} />
        <button
          className="btn btn-primary btn-fullwidth mt-16"
          onClick={this.submit}
        >
          start trial
        </button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
