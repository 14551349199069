import React, { useContext } from "react";
import MenuContext from "./context";
import onClickOutside from "react-onclickoutside";
const classnames = require("classnames");

const Navbar = props => {
  const {
    theme,
    position,
    className,
    style,
    desktop: Desktop,
    desktopAlign,
    children
  } = props;
  const themeClass = theme ? "navbar-" + theme : null;
  const positionClass = position ? "navbar-" + position : null;
  const navClasses = classnames(
    "navbar-responsive",
    "navbar-desktop",
    themeClass,
    className,
    positionClass
  );

  const context = useContext(MenuContext);

  Navbar.handleClickOutside = () => {
    context.setSomethingIsOpen(false);
  };
  return (
    <nav className={navClasses} style={style}>
      <ul className="navbar-responsive__list">
        {!!Desktop ? <Desktop /> : children}
      </ul>
    </nav>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Navbar.handleClickOutside
};

export default onClickOutside(Navbar, clickOutsideConfig);
