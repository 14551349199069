import React from "react";
import { slide as Menu } from "react-burger-menu";
const classnames = require("classnames");

const Hamburger = props => {
  const [open, setOpen] = React.useState(false);

  // classes
  const { theme, position, className, left, style, mobile: Component } = props;
  const themeClass = theme ? "navbar-responsive-" + theme : null;
  const positionClass = position ? "navbar-responsive-" + position : null;
  const bmWrapperClass = classnames("bm-wrapper", {
    "bm-wrapper-left": left || null
  });
  const navClasses = classnames(
    "navbar-responsive",
    "navbar-mobile",
    themeClass,
    className,
    positionClass
  );

  return (
    <div
      className={bmWrapperClass}
      onClick={() => {
        setOpen(false);
      }}
      style={style}
    >
      <Menu
        className={`bm-menu-${theme}`}
        isOpen={open}
        onStateChange={state => {
          setOpen(state.isOpen);
        }}
        {...props}
      >
        {open && (
          <nav className={navClasses}>
            <ul className="menu-items">
              {Component ? <Component /> : props.children}
            </ul>
          </nav>
        )}
      </Menu>
    </div>
  );
};

export default Hamburger;
