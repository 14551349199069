import React, { useState, useEffect } from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import RPaneNavButton from "./RPaneNavButton";
import FullScreenSpinner from "@bluechilli/fullscreenspinner";
import get from "lodash/get";

// const pluralize = (number) => {
//   if(number === 1)
//     return number + " application";

//   return number + " applications";
// }

const RPaneProperty = ({ propertyId }) => {
  const { getLocation, setSelectedLocationData } = useAppState("location")[1];
  const { setComponentInRightPane } = useAppState("mapviewlayout")[1];

  const [locationData, setLocationData] = useState();

  useEffect(() => {
    setLocationData(undefined);
    getLocation(propertyId)
      .then(payload => {
        setLocationData(payload.location);
        setSelectedLocationData(payload.location);
      })
      .catch(_ => {
        setLocationData({ applications: [] });
      });
  }, [propertyId]);

  if (!locationData) {
    return <FullScreenSpinner />;
  }

  const applicationData = locationData.applications;
  return (
    <div className="rpanefeatureinfo">
      <RPaneNavButton
        faIcon={faChevronLeft}
        onClick={() => {
          setComponentInRightPane("propertylist");
        }}
      >
        Back
      </RPaneNavButton>
      <div className="fi-header-single-heading">
        {locationData.formatted_address}

        <div className="smaller-text lite">
          {/* This could be prettier */}
          {locationData.applications.length === 1
            ? "1 application"
            : locationData.applications.length + " applications"}
        </div>
      </div>

      <div className="fi-list">
        {applicationData.map((feat, k) => {
          const application_type = get(feat, "application_types_tags")
            .map(att => get(att, "name"))
            .join(" ");
          return (
            <div
              className="fi-list-item"
              key={k}
              onClick={() => {
                setComponentInRightPane("application", feat.id);
              }}
            >
              <div className="fli-marker">
                <FontAwesomeIcon icon={faFile} />
              </div>
              <div className="fli-info">
                <div className="fli-location">{application_type}</div>
                <div className="fli-appcount">
                  Submitted: {get(feat, "submitted")}
                </div>
              </div>
              <div className="fli-chev">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RPaneProperty;
