import React, { useEffect, Fragment } from "react";
import { connect } from "formik";
import { ElementWrap } from "../../Scaffolding/Scaffolding";
import Show from "@bluechilli/show";

const FormError = props => {
  const { className = "" } = props;
  const { dirty } = props.formik;
  let { status } = props.formik;

  // turn status into a string if there's only
  // a single element in the array
  if (Array.isArray(status)) {
    if (status.length === 1) {
      status = status[0];
    }
  }

  const s = Array.isArray(status) ? (
    <ErrorAsArray status={status} />
  ) : (
    <ErrorAsString status={status} />
  );

  return (
    <ElementWrap className={className} name="submit-button">
      <Show show={dirty && status}>
        <div className={"form-element-form-error " + className}>{s}</div>
      </Show>
    </ElementWrap>
  );
};

const ErrorAsArray = ({ status }) => {
  return (
    <ul>
      {status.map((line, key) => {
        return <li key={key}>{line}</li>;
      })}
    </ul>
  );
};

const ErrorAsString = ({ status }) => <Fragment>{status}</Fragment>;

export default connect(FormError);
