import React, { useCallback, useState, useEffect } from "react";
import throttle from "lodash/throttle";
import "./fullheight.scss";

let node;

const FullHeight = props => {
  const [height, setHeight] = useState(0);

  const measuredRef = useCallback(n => {
    if (n !== null) {
      node = n;
      heightSetter();
    }
  }, []);

  const heightSetter = throttle(() => {
    setHeight(node.clientHeight);
  }, 700);

  useEffect(() => {
    window.addEventListener("resize", heightSetter);
    heightSetter();
  }, []);

  return (
    <div
      ref={measuredRef}
      style={{
        height: "100%",
        width: "100%",
        position: "relative"
      }}
    >
      {height && props.children(height)}
    </div>
  );
};

export default FullHeight;
