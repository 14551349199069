import React, { useEffect } from "react";
import "./home.scss";
import Content from "../Content/Content";
import { useAppState } from "@bluechilli/bcstatemachine";
import Show from "@bluechilli/show";
import PaneRightOpener from "./PaneRightOpener";
import ViewMap from "../ViewMap/ViewMap";
import RightPaneEntryPoint from "../RightPaneEntryPoint/RightPaneEntryPoint";

import MapOverlayBar from "../MapOverlayBar/MapOverlayBar";

const Home = props => {
  // Reach Router sends "*" as a prop,
  // we need this
  const viewpane = props["*"];

  const [
    { leftMenuIsOpen, rightMenuIsOpen },
    { setLeftMenu, setRightMenu }
  ] = useAppState("mapviewlayout");

  const mapPaneClassLeft = leftMenuIsOpen ? "map-pane-left" : "";
  const mapPaneClassRight = rightMenuIsOpen ? "map-pane-right" : "";

  useEffect(() => {
    setLeftMenu(viewpane !== undefined && viewpane !== "");
  }, [viewpane]);

  return (
    <>
      <Content filterBar={true}>
        <MapOverlayBar />

        <div className={`map-pane ${mapPaneClassLeft} ${mapPaneClassRight}`}>
          <ViewMap />
        </div>

        <Show show={rightMenuIsOpen}>
          <div className="pane-right">
            <RightPaneEntryPoint />
          </div>
        </Show>

        <PaneRightOpener
          open={rightMenuIsOpen}
          onClick={() => {
            setRightMenu(!rightMenuIsOpen);
          }}
        />
      </Content>
    </>
  );
};

export default Home;
