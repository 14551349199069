import React from "react";
import { Col, Container, Row } from "react-grid-system";

import SignupForm from "../Forms/SignupForm/SignupForm.jsx";
import Navbar from "../../Navbar/Navbar";

const Signup = () => {
  return (
    <>
      <Navbar noSearch={true} />
      <Container fluid className="narrow-form">
        <Row className="pt-64" justify="center">
          <Col md={12} align="center">
            <h1 className="mb-32">register</h1>

            <h3 className="mb-32">please enter your details</h3>

            <SignupForm />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Signup;
