import { useState } from "react";

const useMap = () => {
  // Default Map location when user lands on stateless site
  const [center, setCenter] = useState({ lat: -33.871965, lng: 151.2130415 });
  const [zoom, setZoom] = useState(16);
  const [bounds, setBounds] = useState();
  const [searchMarker, setSearchMarker] = useState();
  const [mapViewId, setMapViewId] = useState("default");
  const [selectedLayerNumber, setSelectedLayerNumber] = useState(0);
  const [selectedLot, setSelectedLot] = useState(null);

  const [currentImageMapLayer, setImageMapLayer] = useState(null);
  const [currentLotMapLayer, setCurrentLotMapLayer] = useState(null);
  const [searchString, setSearchString] = useState();

  // sets the state of the app based on the leaflet object
  const syncMapStateWithLeafletElement = le => {
    setBounds(boundsToArray(le.getBounds()));
    setZoom(le.getZoom());
    setCenter(le.getCenter());
  };

  return [
    {
      center,
      zoom,
      bounds,
      searchMarker,
      mapViewId,
      selectedLayerNumber,
      selectedLot,
      currentImageMapLayer,
      currentLotMapLayer,
      searchString
    },
    {
      setCenter,
      setZoom,
      syncMapStateWithLeafletElement,
      setSearchMarker,
      setMapViewId,
      setImageMapLayer,
      setSelectedLayerNumber,
      setBounds,
      setSelectedLot,
      setCurrentLotMapLayer,
      setSearchString
    }
  ];
};

/*
  HELPERS
 */

// turns a leaflet bounds object into a flat array
const boundsToArray = bounds => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return [ne.lat, ne.lng, sw.lat, sw.lng];
};

export default useMap;
