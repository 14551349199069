import * as Yup from "yup";

const contactFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  name: Yup.string().required("Name is required"),
  subject: Yup.string().required("subject is required"),
  message: Yup.string().required("Message is required")
});

export default contactFormValidationSchema;
