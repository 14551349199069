const statusList = [
  {
    label: "Approved",
    value: "4"
  },
  {
    label: "Pending",
    value: "1"
  },
  {
    label: "Withdrawn",
    value: "2"
  },
  {
    label: "Not approved",
    value: "3"
  },
  {
    label: "Other",
    value: "5"
  }
];

export default statusList;
