import React from "react";
import PropTypes from "prop-types";
import { Field, connect } from "formik";
import {
  ElementWrap,
  ErrorContainer,
  InputWrap,
  LabelWrap
} from "../../Scaffolding/Scaffolding";

const RadioButton = props => {
  const {
    name,
    options = [],
    className,
    formik,
    insert = () => {},
    onChange = () => {},
    ...rest
  } = props;

  return (
    <ElementWrap className={`${className}`}>
      {options.map((option, index) => {
        return (
          <React.Fragment key={`${option.label}-${index}`}>
            <InputWrap
              className="form-radio-element"
              key={`${option.label}-${index}`}
            >
              <Field
                id={`${option.label}-${index}`}
                onChange={event => {
                  formik.setFieldValue(name, event.target.value);
                  onChange(option);
                }}
                value={option.value}
                checked={option.value === formik.values[name]}
                name={name}
                type="radio"
              />
              <LabelWrap name={name} label={option.label}>
                <label htmlFor={`${option.label}-${index}`}>
                  {option.label}
                </label>
              </LabelWrap>
            </InputWrap>
            {insert(option)}
          </React.Fragment>
        );
      })}
      <ErrorContainer />
    </ElementWrap>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.array.isRequired
};

export default connect(RadioButton);
