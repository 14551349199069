import React from "react";
import "./spinneronmap.scss";
import { FadingCircle } from "@bluechilli/spinners";

const SpinnerOnMap = () => {
  return (
    <div className="spinneronmap">
      <FadingCircle />
    </div>
  );
};

export default SpinnerOnMap;
