import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { RadioButton } from "@bluechilli/chilliforms";
import planningLayers from "../../constants/planningLayers";
import { useAppState } from "@bluechilli/bcstatemachine";
import DropDownContext from "../FilterDropDown/DropDownContext";

const LayerTypes = () => {
  const [{ selectedLayerNumber }, { setSelectedLayerNumber }] = useAppState(
    "map"
  );

  const { setIsOpened } = useContext(DropDownContext);

  const onChange = values => {
    setSelectedLayerNumber(values.planningLayers);
    return {};
  };

  return (
    <>
      <Formik
        initialValues={{
          planningLayers: selectedLayerNumber || ""
        }}
        validate={onChange}
        render={() => {
          return (
            <Form>
              <RadioButton
                className="ddcompact"
                options={planningLayers}
                name="planningLayers"
                value={true}
                onChange={() => {
                  setIsOpened(false);
                }}
              />
            </Form>
          );
        }}
      />
    </>
  );
};

export default LayerTypes;
