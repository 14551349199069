import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { useAppState } from "@bluechilli/bcstatemachine";
import CheckBoxFieldArray from "@bluechilli/chilliforms/src/components/CheckboxFieldArray/CheckboxFieldArray";

import statusList from "../../constants/statusList";
import DropDownContext from "../FilterDropDown/DropDownContext";

const DDFilterTypes = () => {
  const [{ statuses }, { updateStatusList }] = useAppState("filterPersistence");

  const onChange = values => {
    updateStatusList(values.filterstatus);
    return {};
  };

  const { setIsOpened } = useContext(DropDownContext);

  return (
    <>
      <Formik
        initialValues={{ filterstatus: statuses }}
        validate={onChange}
        render={({ setValues }) => {
          return (
            <Form>
              <CheckBoxFieldArray data={statusList} name="filterstatus" />
              <div className="dd-footer">
                <div
                  onClick={e => {
                    setValues({ filterstatus: {} });
                    updateStatusList({});
                    setIsOpened(false);
                    e.stopPropagation();
                  }}
                >
                  Clear
                </div>
              </div>
            </Form>
          );
        }}
      />
    </>
  );
};

export default DDFilterTypes;
