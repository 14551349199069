import React from "react";
import "./contact.scss";
import { Col, Container, Row } from "react-grid-system";
import Content from "../Content/Content";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <Content noSearch={true} className="inverted pt-32 pb-32">
      <Container className="contact">
        <Row justify="center">
          <Col lg={6} md={6}>
            <h2>Contact</h2>
            <p>Send us a message by completing the form below</p>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Content>
  );
};

export default Contact;
