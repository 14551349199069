import React from "react";
import { Field } from "formik";
import get from "lodash/get";
import {
  ElementWrap,
  HelperText,
  ErrorContainer,
  LabelWrap,
  InputWrap
} from "../../Scaffolding/Scaffolding";

function CheckboxField(props) {
  const {
    label,
    helperText = "",
    parent,
    className = "",
    value,
    isChecked
  } = props;

  const name = `${parent}[${value}]`;

  return (
    <ElementWrap className={`checkbox ${className}`} name={name}>
      <InputWrap name={name}>
        <Field
          name={name}
          type="checkbox"
          value={value}
          render={({ field, form }) => {
            const checked = !!get(form, ["values", parent, value]);
            return (
              <input
                id={name}
                className={`checkbox ${className}`}
                type="checkbox"
                checked={checked || isChecked}
                {...field}
              />
            );
          }}
        />
        <span className={` checkbox checked`} />
      </InputWrap>
      <LabelWrap name={name} label={label}>
        <label className={`form-element-label ${className}`} htmlFor={name}>
          {label || name}
        </label>
        <HelperText text={helperText} />
      </LabelWrap>

      <ErrorContainer name={name} />
    </ElementWrap>
  );
}

export default CheckboxField;
