import React from "react";
import Navbar from "../Navbar/Navbar";
import "./content.scss";
import classnames from "classnames";
import { Modal } from "@bluechilli/bcmodal";
import PayWall from "../PayWall/PayWall";
import SaveSearchModal from "../SaveSearchModal/SaveSearchModal";
import CancelSubscription from "../CancelSubscription/CancelSubscription";
import FilterBar from "../FilterBar/FilterBar";

const Home = ({ children, className, noSearch = false, filterBar = false }) => {
  const classes = filterBar
    ? classnames("page-content-map", className)
    : classnames("page-content", className);

  return (
    <div className="page-wrapper">
      <div className="top">
        <Navbar noSearch={noSearch} />
        {filterBar && <FilterBar />}
      </div>
      <Modal id="cancel" noTopBar={true}>
        <CancelSubscription />
      </Modal>

      <Modal id="paywall" noTopBar={true}>
        <PayWall />
      </Modal>

      <Modal id="savedsearch" titleText="Save search">
        <SaveSearchModal />
      </Modal>

      <div className={classes}>{children}</div>
    </div>
  );
};

export default Home;
