import React, { Fragment, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import menuContext from "./context";
import Show from "@bluechilli/show";

const classnames = require("classnames");

const SubMenu = props => {
  const [expanded, setExpanded] = React.useState(false);
  const context = useContext(menuContext);
  const { className, style } = props;
  const dropdownClasses = classnames("submenu-items", className);

  const toggleState = event => {
    event.preventDefault();
    event.stopPropagation();

    // Close all menus down first
    context.setSomethingIsOpen(false);

    // Open submenu
    setTimeout(() => {
      context.setSomethingIsOpen(true);
      setExpanded(!expanded);
    }, 1);
  };

  useEffect(() => {
    if (context.somethingIsOpen === false) {
      setExpanded(false);
    }
  });

  return (
    <Fragment>
      <li className={"navbar-responsive__list-item"}>
        <span className="submenu-item" onClick={event => toggleState(event)}>
          {props.label}

          <FontAwesomeIcon icon={faCaretDown} />
        </span>
        <Show show={expanded} anim="fade">
          <ul className={dropdownClasses} style={style || null}>
            {props.children}
          </ul>
        </Show>
      </li>
    </Fragment>
  );
};

export default SubMenu;
