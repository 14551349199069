import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";
import {
  ElementWrap,
  LabelWrap,
  HelperText,
  InputWrap
} from "../../Scaffolding/Scaffolding";
import { connect } from "formik";
import googleAddressSearch from "./googleAddressSearch";

const LocationInputField = ({
  className,
  dropdownClassName = "form-locationinputfield-dropdown",
  suggestionClassName = "suggestion-item",
  name,
  label,
  helperText,
  defaultValue = "",
  formik,
  placeholder = "Search location",
  onSelect = () => {},
  ...props
}) => {
  const { setFieldValue, setFieldTouched, values } = formik;
  const [address, setAddress] = React.useState(defaultValue);

  const handleChange = address => {
    setFieldTouched(name, true);
    setAddress(address);
  };

  const handleSelect = address => {
    googleAddressSearch(address)
      .then(results => {
        setFieldValue(name, results);
        setAddress(results.formattedAddress);
        onSelect(results);
      })
      .catch(error => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      searchOptions={{
        componentRestrictions: { country: "AU" }
      }}
      onSelect={handleSelect}
      onChange={handleChange}
      {...props}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <ElementWrap className={className}>
            <LabelWrap name={name}>
              <label htmlFor={name}>{label || name}</label>
              <HelperText text={helperText} />
            </LabelWrap>

            <div>
              <InputWrap name={name}>
                <input
                  {...getInputProps({
                    placeholder,
                    className: "location-search-input"
                  })}
                />
              </InputWrap>
            </div>
            {address.length !== 0 && (
              <div className={dropdownClassName}>
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? `${suggestionClassName}--active`
                    : suggestionClassName;
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </ElementWrap>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default connect(LocationInputField);
