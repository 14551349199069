import React from "react";
import { Link } from "@reach/router";

import Content from "../../Content/Content.jsx";

import { Col, Container, Row } from "react-grid-system";

import "./myaccount.scss";
import { useAppState } from "@bluechilli/bcstatemachine";
import Show from "@bluechilli/show";

const AccountLink = props => {
  return (
    <Link
      to={"/user/myaccount/" + props.goto}
      getProps={({ isCurrent }) => (isCurrent ? { className: "active" } : null)}
      {...props}
    />
  );
};

const Index = ({ children }) => {
  const [{ isFinancial }] = useAppState("user");
  return (
    <Content className="inverted pt-32 pb-32" noSearch={true}>
      <Container>
        <Row justify="center">
          <Col md={12} align="center">
            <h1 className="mb-32">my account</h1>

            <h3 className="mb-32">
              access and edit your account details and settings
            </h3>
          </Col>
        </Row>

        <Row justify="center">
          <Col lg={3} md={4}>
            <ul id="account-tabs">
              <li>
                <AccountLink goto="mydetails">My Details</AccountLink>
              </li>
              <Show show={isFinancial}>
                <li>
                  <AccountLink goto="notifications">
                    Notification Settings
                  </AccountLink>
                </li>
                <li>
                  <AccountLink goto="billing">Billing Details</AccountLink>
                </li>
              </Show>
            </ul>
          </Col>

          <Col lg={7} md={8}>
            <div id="account-content">{children}</div>
          </Col>
        </Row>
      </Container>
    </Content>
  );
};

export default Index;
