import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import "./image-upload.scss";

export default function ImageUpload({
  handleUpdatePromise,
  placeholderImagePath,
  preloadedImagePath,
  customPlusIcon,
  ...other
}) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      handleUpdatePromise(acceptedFiles)
        .then(res => {
          setFiles(
            acceptedFiles.map(file =>
              Object.assign(file, {
                preview: URL.createObjectURL(file)
              })
            )
          );
        })
        .catch(error => {
          setFiles([]);
          console.error(error)
        });
    }
  });

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className="image-uploader" {...other}>
      <div className="dropzone-container" {...getRootProps()}>
        <input className="" {...getInputProps()} />
        {files.length > 0 ? (
          <img
            className="avatar-placeholder"
            src={files[0].preview}
            alt="uploaded preview"
          />
        ) : preloadedImagePath ? (
          <img
            className="avatar-placeholder"
            src={preloadedImagePath}
            alt="uploaded preview"
          />
        ) : (
          <img
            className="avatar-placeholder"
            src={placeholderImagePath}
            alt="profile placeholder"
          />
        )}
        <div className="plus-icon" alt="plus icon">
          {customPlusIcon ? customPlusIcon : "+"}
        </div>
      </div>
    </div>
  );
}
