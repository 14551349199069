import React from "react";
import { ResponsiveMenu, MenuItem } from "@bluechilli/responsivemenu";
import ImbyLogo from "../ImbyLogo/ImbyLogo.jsx";

import { Col, Container, Row } from "react-grid-system";
import "./navbar.scss";
import { useAppState } from "@bluechilli/bcstatemachine";
import AddressSearch from "../AddressSearch/AddressSearch";

import { Link } from "@reach/router";

const Navbar = ({ noSearch = false }) => {
  const [{ isLoggedIn, isFinancial }] = useAppState("user");

  return (
    <div className="navbar">
      <Container fluid>
        <Row>
          <Col xs={10} md={6} lg={6} xl={8} className="navbar-left">
            <div className="navbar-logo">
              <Link to="/">
                <ImbyLogo />
              </Link>
            </div>
            {!noSearch && (
              <div className="navbar-addresssearch">
                <AddressSearch />
              </div>
            )}
          </Col>
          <Col xs={2} md={6} lg={6} xl={4} style={{ textAlign: "right" }}>
            <ResponsiveMenu right desktopAlign="right">
              <MenuItem to="/">map</MenuItem>
              <MenuItem
                showIf={!isLoggedIn || !isFinancial}
                to="/user/selectplan"
              >
                pricing
              </MenuItem>

              <MenuItem to="/contact">contact</MenuItem>

              <MenuItem showIf={isLoggedIn} to="/user/myaccount/mydetails">
                my account
              </MenuItem>
              <MenuItem showIf={!isLoggedIn} to="/user/login">
                log in
              </MenuItem>
              <MenuItem showIf={isLoggedIn} to="/user/logout">
                log out
              </MenuItem>
            </ResponsiveMenu>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navbar;

/*


 */
