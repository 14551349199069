import L from "leaflet";

export const marker1 = L.icon({
  iconUrl: "/markers/marker1.png",
  iconSize: [20, 20], // size of the icon
  iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -7] // point from which the popup should open relative to the iconAnchor
});

export const marker2 = L.divIcon({
  iconUrl: "/markers/marker2.png",
  iconSize: [20, 20], // size of the icon
  iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -7], // point from which the popup should open relative to the iconAnchor
  html:
    '<div class="gps_ring"><img alt="icon" src="/markers/marker2.png"></div>'
});

export const search = L.icon({
  iconUrl: "/markers/search.png",
  shadowUrl: "/markers/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
