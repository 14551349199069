import React from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import AriaModal from "react-aria-modal";

const Modal = ({
  children,
  closeHandler = () => {},
  className = "",
  titleText = "",
  id = "__whatever",
  nonInteractive = false,
  noTopBar = false,
  isOpen = false
}) => {
  const modal = useAppState("modal");

  function closeMe() {
    modal.close(id);
    closeHandler();
  }

  function getApplicationNode() {
    return document.getElementById("root");
  }

  const modalHeaderClass = noTopBar === false ? "modal-header" : "";

  return (
    <AriaModal
      titleText={titleText}
      initialFocus={`#${id}`}
      onExit={closeMe}
      titleId={id}
      mounted={modal.isOpen(id) || isOpen}
      getApplicationNode={getApplicationNode}
      underlayClass="modal-wrapper"
    >
      <div className={className}>
        <div className="modal-box">
          <div className={modalHeaderClass}>
            <div className="modal-header-content">
              <div className="text">{titleText}</div>
              {!nonInteractive && noTopBar !== true && (
                <div className="close">
                  <button
                    id={id}
                    onClick={() => {
                      closeMe();
                    }}
                  >
                    x
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </AriaModal>
  );
};

Modal.defaultProps = {
  meta: {}
};

export default Modal;
