import React, { useEffect } from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import { navigate } from "@reach/router";

const ProtectedRoute = ({ component, ...rest }) => {
  const [{ isLoggedIn }] = useAppState("user");
  const Component = component;
  useEffect(() => {
    if (isLoggedIn === false) {
      navigate("/");
    }
  }, []);

  if (isLoggedIn === true) {
    return <Component {...rest} />;
  } else return null;
};

export default ProtectedRoute;
