import React, { useEffect, useState } from "react";
import "./forgotpassword.scss";
import { useAppState } from "@bluechilli/bcstatemachine";
import { Link, navigate } from "@reach/router";
import Content from "../../Content/Content";
import { Col, Container, Row } from "react-grid-system";
import { Form, Formik } from "formik";

import { FormError, SubmitButton, TextField } from "@bluechilli/chilliforms";
import forgotpasswordFormValidationSchema from "@bluechilli/accounts/src/components/ForgotPassword/forgotpasswordFormValidationSchema";
import Show from "@bluechilli/show";

const ForgotPassword = () => {
  const { forgotPassword } = useAppState("user")[1];
  const [emailSent, setEmailSent] = useState(false);

  return (
    <Content noSearch={true}>
      <Container fluid className="narrow-form">
        <Row>
          <Col className="pt-32" align="center">
            <h1>Forgot Password</h1>
          </Col>
        </Row>

        <Show show={emailSent}>
          <Row className="pt-32" justify="center">
            <Col md={12} align="center">
              <p>Please check your email for instructions</p>

              <p>
                <Link to="/user/login">Click to log back in</Link>
              </p>
            </Col>
          </Row>
        </Show>

        <Show show={!emailSent}>
          <Row className="pt-32" justify="center">
            <Col md={12} align="center">
              <Formik
                isInitialValid={true}
                validationSchema={forgotpasswordFormValidationSchema}
                onSubmit={(values, { setErrors, setSubmitting }) => {
                  const { email } = values;

                  forgotPassword(email)
                    .then(_ => {
                      setEmailSent(true);
                    })
                    .catch(error => {
                      // happens anyway
                      setEmailSent(true);
                    });
                }}
                initialValues={{ email: "" }}
                render={({ errors, isSubmitting }) => {
                  return (
                    <Form>
                      <Container fluid>
                        <Row>
                          <Col>
                            <TextField
                              label="Email address"
                              name="email"
                              type="email"
                              placeholder="Please enter your email address"
                              autoFocus={true}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormError />

                            <SubmitButton
                              className="form-button"
                              value="Send"
                              submittingValue="Sending .."
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  );
                }}
              />
            </Col>
          </Row>
        </Show>
      </Container>
    </Content>
  );
};

export default ForgotPassword;
