const dateRanges = [
  {
    label: "Last week",
    value: "last 7 days",
    absolute: false
  },
  {
    label: "Last month",
    value: "last 30 days",
    absolute: false
  },
  {
    label: "Last 3 Months",
    value: "last 90 days",
    absolute: false
  },
  {
    label: "Last 6 Months",
    value: "last 180 days",
    absolute: false
  },
  {
    label: "Last Year",
    value: "last 365 days",
    absolute: false
  },
  {
    label: "Last 3 Years",
    value: "last 730 Days",
    absolute: false
  },
  {
    label: "Last 5 Years",
    value: "last 1095 Days",
    absolute: false
  }

];

export default dateRanges;
