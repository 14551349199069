import axiosInstance from "./axiosInstance";
const queryString = require("query-string");

export function apiGetMarkers(neLat, neLng, swLat, swLng, startDate, endDate) {
  const qs = queryString.stringify({
    ne: `${neLat},${neLng}`,
    sw: `${swLat},${swLng}`,
    from: startDate.format("YYYY-MM-DD"),
    to: endDate.format("YYYY-MM-DD")
  });

  return axiosInstance.get("markers?" + qs);
}

export function apiGetMarkersQuery(query) {
  return axiosInstance.get("map/markers?query=" + query);
}

export function apiGetLocations(filter) {
  const qs = queryString.stringify({
    filter
  });
  return axiosInstance.get("locations?" + qs);
}
