import React, { useState, useEffect, useContext } from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { useAppState } from "@bluechilli/bcstatemachine";
import debounce from "lodash/debounce";

import DropDownContext from "../FilterDropDown/DropDownContext";

const intelligentRounder = value => {
  if (value < 1000000) {
    return Math.round(value / 100000) * 100000;
  } else {
    return Math.round(value / 1000000) * 1000000;
  }
};

const valueToString = value => {
  if (value < 1000000) {
    if (value === 0) return "0";

    return Math.round(value / 1000) + "k";
  } else {
    return Math.round(value / 1000000) + "m";
  }
};

const valuesToString = (v1, v2) => {
  if (v1 === 0) v1 = undefined;
  if (v2 === 10000000) v2 = undefined;

  if (v1 === undefined && v2 === undefined) return "No restriction";

  if (!v1) {
    if (!v2) {
      return "No Restriction";
    } else {
      return "Up to $" + valueToString(v2);
    }
  }

  if (!v2) {
    return `Minimum price $${valueToString(v1)}`;
  } else {
    return `From $${valueToString(v1)} to $${valueToString(v2)}`;
  }
};

let initialLow, initialHigh;

const DDEstValue = () => {
  const [{ estValueLow, estValueHigh }, { updateEstValues }] = useAppState(
    "filterPersistence"
  );

  const { setIsOpened } = useContext(DropDownContext);

  const [low, setLow] = useState();
  const [high, setHigh] = useState();

  useEffect(() => {
    setLow(estValueLow);
    setHigh(estValueHigh);
    initialLow = estValueLow;
    initialHigh = estValueHigh;
  }, []);

  const onSlide = (render, handle, value) => {
    const l = intelligentRounder(value[0]);
    const h = intelligentRounder(value[1]);

    setLow(l);
    setHigh(h);

    debouncedSlideHandler(render, handle, value);
  };

  const debouncedSlideHandler = debounce((render, handle, value) => {
    const l = intelligentRounder(value[0]);
    const h = intelligentRounder(value[1]);

    updateEstValues(l, h);
  }, 300);

  const resetAll = () => {
    initialLow = 0;
    initialHigh = 10000000;
    setLow(0);
    setHigh(10000000);
    updateEstValues(0, 10000000);
  };

  return (
    <div>
      <div className="pt-12">
        <strong>{valuesToString(low, high)}</strong>
      </div>
      <div className="pt-64 pb-64">
        <Nouislider
          connect
          start={[
            initialLow ? initialLow : 0,
            initialHigh ? initialHigh : 10000000
          ]}
          behaviour="tap-drag"
          range={{
            min: [0],
            max: [10000000]
          }}
          onSlide={onSlide}
        />
      </div>
      <div className="dd-footer">
        <div
          onClick={e => {
            resetAll();
            setIsOpened(false);
            e.stopPropagation();
          }}
        >
          Clear
        </div>
      </div>{" "}
    </div>
  );
};

export default DDEstValue;
