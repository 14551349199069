import React, { useState, useEffect } from "react";
import "./selectplan.scss";
import { Col, Container, Row } from "react-grid-system";

import PlanPane from "./PlanPane";

import iconNational from "../../../assets/images/icon-national.svg";
import iconState from "../../../assets/images/icon-state.svg";
import iconRegional from "../../../assets/images/icon-regional.svg";
import iconLocal from "../../../assets/images/icon-local.svg";
import { useAppState } from "@bluechilli/bcstatemachine";
import { Link, navigate } from "@reach/router";
import Navbar from "../../Navbar/Navbar";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectPlan = () => {
  const [plan, setPlan] = useState(false);
  const [
    { desiredPlan, isLoggedIn, isFinancial },
    { setDesiredPlan }
  ] = useAppState("user");

  useEffect(() => {
    if (desiredPlan) {
      setPlan(desiredPlan);
    }
  }, []);

  useEffect(() => {
    if (isFinancial === true) {
      navigate("/");
    }
  }, [isFinancial]);

  const planSelectHandler = plan => {
    setDesiredPlan(plan);
    if (isLoggedIn) {
      navigate("/user/payment");
    } else {
      navigate("/user/signup");
    }
  };

  return (
    <>
      <Navbar noSearch={true} />
      <Container fluid className="selectplan">
        <Container>
          <Row className="pt-32" justify="center">
            <Col md={12} align="center">
              <h1 className="mb-32">plans and pricing</h1>
              <h3 className="mb-32">
                whether your business is large or small we have a plan to help
                you stay ahead
              </h3>
            </Col>
          </Row>

          <Row className="inclusions">
            <Col md={12} align="center">
              <h2>included in all plans</h2>
            </Col>
            <Row className="inclusion">
              <Col md={4} sm={6}>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>
                      Access to rich data presented on a map for easy
                      referencing
                    </p>
                  </li>
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>Clean data correlated to National Construction Code</p>
                  </li>
                </ul>
              </Col>
              <Col md={4} sm={6}>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>Links to development details for each development</p>
                  </li>
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>
                      Customisable search profile to see all relevant
                      opportunities
                    </p>
                  </li>
                </ul>
              </Col>
              <Col md={4} sm={6}>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>
                      Layers to view planning controls relevant to your search
                      area
                    </p>
                  </li>
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>
                      Email notification service to help you keep ahead of
                      what's developing
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Row>

          <Row>
            <Col md={3} sm={6}>
              <PlanPane
                img={iconNational}
                price={350}
                name="national"
                descriptions={["National coverage across the country"]}
                onClick={() => {
                  planSelectHandler("national");
                }}
                selected={plan === "national"}
              />
            </Col>
            <Col md={3} sm={6}>
              <PlanPane
                img={iconState}
                price={185}
                name="state"
                descriptions={[
                  "Coverage for your nominated State or Territory"
                ]}
                onClick={() => {
                  planSelectHandler("state");
                }}
                selected={plan === "state"}
              />
            </Col>
            <Col md={3} sm={6}>
              <PlanPane
                img={iconRegional}
                price={125}
                name="regional"
                descriptions={[
                  "Coverage for your nominated Regions",
                  "Up to 5 nominated approval Authorities"
                ]}
                onClick={() => {
                  planSelectHandler("regional");
                }}
                selected={plan === "regional"}
              />
            </Col>
            <Col md={3} sm={6}>
              <PlanPane
                img={iconLocal}
                price={85}
                name="local"
                descriptions={[
                  "Coverage for your nominated Regions",
                  "Up to 2 nominated approval Authorities"
                ]}
                onClick={() => {
                  planSelectHandler("local");
                }}
                selected={plan === "local"}
              />
            </Col>
          </Row>

          <Row className="inclusions">
            <Col md={12} align="center">
              <h2>enterprise plans</h2>
            </Col>
            <Row className="inclusion">
              <Col md={4} sm={6}>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>Multiple users to suit your team size</p>
                  </li>
                </ul>
              </Col>
              <Col md={4} sm={6}>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>Customised reporting optimised to your market</p>
                  </li>
                </ul>
              </Col>
              <Col md={4} sm={6}>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <p>API and portal integration to compliment your data</p>
                  </li>
                </ul>
              </Col>
            </Row>

            <Col md={12} align="center" className="mt-32">
              <Link to="/contact" className="select-plan-button narrow">
                contact us
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default SelectPlan;
