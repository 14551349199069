import * as Yup from "yup";

const SignupFormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  name: Yup.string().required("First Name is required"),
  surname: Yup.string().required("Last Name is required"),
  password: Yup.string().required("Password is required"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Repeat password is required")
});

export default SignupFormSchema;
