import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppState } from "@bluechilli/bcstatemachine";

const RPaneNavButton = ({ children, onClick, faIcon, text = "back" }) => {
  const [{ screenSize }] = useAppState("mapviewlayout");

  const isMobile = screenSize === "small";

  if (isMobile) {
    return (
      <div className="rpanenavbutton" onClick={onClick}>
        <span className="rpanenavbutton-icon">
          <FontAwesomeIcon icon={faIcon} />
        </span>
      </div>
    );
  } else {
    return (
      <div className="rpanenavbutton-desktop" onClick={onClick}>
        <span className="rpanenavbutton-icon">
          <FontAwesomeIcon icon={faIcon} />
        </span>
        <span className="rpanenavbutton-text">{text}</span>
      </div>
    );
  }
};

export default RPaneNavButton;
