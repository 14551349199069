import React, { useEffect } from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import RPaneNavButton from "./RPaneNavButton";
import FullScreenSpinner from "@bluechilli/fullscreenspinner";
import PayWall from "../PayWall/PayWall";

const RPanePropertyNonFinancial = ({ propertyId }) => {
  const [
    { isFetching, locations, selectedLocationData },
    { setSelectedLocationData }
  ] = useAppState("location");

  const { setComponentInRightPane } = useAppState("mapviewlayout")[1];

  useEffect(() => {
    if (!isFetching) {
      const test = locations.entries.find(loc => {
        return parseInt(loc.location_id) === parseInt(propertyId);
      });
      if (test) {
        setSelectedLocationData(test);
      } else {
        setComponentInRightPane("propertylist");
      }
    }
  }, [JSON.stringify(locations), propertyId, isFetching]);

  if (!selectedLocationData) return <FullScreenSpinner />;

  return (
    <div className="rpanefeatureinfo">
      <RPaneNavButton
        faIcon={faChevronLeft}
        onClick={() => {
          setComponentInRightPane("propertylist");
        }}
      >
        Back
      </RPaneNavButton>
      <div className="rpane-content">
        <div className="fi-header-single-heading">
          {selectedLocationData.location}
          <div className="smaller-text">
            Applications: {selectedLocationData.applicationCount}
          </div>
        </div>
        <div className="fi-header-paywall">
          <PayWall />
        </div>
      </div>
    </div>
  );
};

export default RPanePropertyNonFinancial;
