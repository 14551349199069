import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { useAppState } from "@bluechilli/bcstatemachine";
import CheckBoxFieldArray from "@bluechilli/chilliforms/src/components/CheckboxFieldArray/CheckboxFieldArray";

import typeList from "../../constants/typeList";
import DropDownContext from "../FilterDropDown/DropDownContext";

const DDFilterTypes = () => {
  const [{ types }, { updateTypeList }] = useAppState("filterPersistence");
  const { setIsOpened } = useContext(DropDownContext);

  const onChange = values => {
    updateTypeList(values.resFilter);
    return {};
  };

  return (
    <>
      <Formik
        initialValues={{ resFilter: types }}
        validate={onChange}
        render={({ setValues }) => {
          return (
            <Form>
              <CheckBoxFieldArray data={typeList} name="resFilter" />
              <div className="dd-footer">
                <div
                  onClick={e => {
                    setValues({ resFilter: {} });
                    updateTypeList({});
                    setIsOpened(false);
                    e.stopPropagation();
                  }}
                >
                  Clear
                </div>
              </div>
            </Form>
          );
        }}
      />
    </>
  );
};

export default DDFilterTypes;
