import React, { useEffect, useState } from "react";
import "./payment.scss";
import { Col, Container, Row } from "react-grid-system";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./CheckOutForm";
import { useAppState } from "@bluechilli/bcstatemachine";
import get from "lodash/get";
import { Link, navigate } from "@reach/router";
import Navbar from "../../Navbar/Navbar";
import plans from "../../../constants/plans";
import FullScreenSpinner from "@bluechilli/fullscreenspinner";
import Show from "@bluechilli/show";
import env from "@bluechilli/env";

const Payment = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [
    { desiredPlan, userData },
    { registerCardAndPlan, getUserData }
  ] = useAppState("user");

  useEffect(() => {
    if (!desiredPlan) navigate("/user/selectplan");
  }, []);

  const { email, name, surname } = get(userData, "profile.data", {});

  if (!desiredPlan) return null;

  return (
    <>
      <Navbar noSearch={true} />
      <Show show={isProcessing}>
        <FullScreenSpinner txt="Processing your Payment" />
      </Show>
      <Container fluid className="payment">
        <Row className="pt-64" justify="center">
          <Col md={12} align="center">
            <h1 className="mb-32">payment details</h1>

            <h3 className="mb-32">please enter your payment details</h3>
          </Col>
        </Row>
        <Container>
          <Row justify="center">
            <Col md={6} sm={8}>
              <StripeProvider apiKey={env("REACT_APP_STRIPE_KEY")}>
                <div className="payment-box">
                  <div className="payment-user mb-16">
                    <div>
                      <strong>Name:</strong> {name} {surname} ({email})
                    </div>
                    <div>
                      <strong>Plan:</strong> {desiredPlan.toUpperCase()}
                      <span className="payment-change-plan">
                        <Link to="/user/selectplan">Change Plan</Link>
                      </span>
                    </div>
                  </div>
                  <Elements>
                    <CheckoutForm
                      onSubmit={(token = {}) => {
                        if (token.id) {
                          setIsProcessing(true);
                          registerCardAndPlan(token.id, plans[desiredPlan])
                            .then(payload => {
                              getUserData().then(_ => navigate("/"));
                            })
                            .catch(e => {
                              setIsProcessing(false);
                              alert(e.response.data.message);
                            });
                        } else {
                          setIsProcessing(false);
                          alert("Your Card is invalid.");
                        }
                      }}
                    />
                  </Elements>
                </div>
              </StripeProvider>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Payment;
