import { useState } from "react";

const useTimer = () => {
  const [timerOn, setIsReady] = useState(false);

  const startTimer = length => {
    setIsReady(true);
    setTimeout(() => {
      setIsReady(false);
    }, length);
  };

  return { timerOn, startTimer };
};

export default useTimer;
