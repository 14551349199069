import React from "react";
import "./loader.scss";

const Loader = props => {
  return (
    <div className="bc-loader">
      <div />
      <div />
    </div>
  );
};

export default Loader;
