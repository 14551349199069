import React, { useState } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";

import { Col, Container, Row } from "react-grid-system";
import { useAppState } from "@bluechilli/bcstatemachine";
import useTimer from "@bluechilli/usetimer";
import env from "@bluechilli/env";
import UpdateCardForm from "./UpdateCardForm";

import FullScreenSpinner from "@bluechilli/fullscreenspinner";
import Show from "@bluechilli/show";

const Login = () => {
  const { updateCard } = useAppState("user")[1];

  const modal = useAppState("modal");
  const { timerOn, startTimer } = useTimer();
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <>
      <Show show={isProcessing}>
        <FullScreenSpinner txt="Processing your Payment" useAllScreen />
      </Show>
      <Container fluid>
        <Row>
          <Col md={12}>
            <h4>Change your card</h4>
          </Col>
        </Row>

        <Row>
          <Col xl={12}>
            <StripeProvider apiKey={env("REACT_APP_STRIPE_KEY")}>
              <Elements>
                <UpdateCardForm
                  onSubmit={(token = {}) => {
                    setIsProcessing(true);

                    if (token.id) {
                      updateCard(token.id)
                        .then(_ => {
                          setIsProcessing(false);
                          startTimer(3000);
                        })
                        .catch(e => {
                          setIsProcessing(false);

                          alert(e.response.data.message);
                        });
                    } else {
                      setIsProcessing(false);
                    }
                  }}
                />
              </Elements>
            </StripeProvider>
            <Show show={timerOn} anim="fade">
              <div className="pt-16">Your card details have been updated</div>
            </Show>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h4>Other Options</h4>
          </Col>

          <Col xl={12}>
            <button
              className="btn btn-warning btn-fullwidth"
              onClick={() => {
                modal.open("cancel");
              }}
            >
              Cancel your subscription
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
