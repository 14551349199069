import React, { useState, useEffect } from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import RPaneNavButton from "./RPaneNavButton";
import FullScreenSpinner from "@bluechilli/fullscreenspinner";
import { Link } from "@reach/router";

const RPaneSavedSearches = ({ propertyId }) => {
  const { setComponentInRightPane } = useAppState("mapviewlayout")[1];
  const { updateFilterString } = useAppState("filterPersistence")[1];

  const { getSearches, deleteSearch } = useAppState("user")[1];

  const [savedSearches, setSavedSearches] = useState([]);

  const loadSearches = () => {
    setSavedSearches([]);
    getSearches().then(payload => {
      setSavedSearches(payload.features);
    });
  };

  useEffect(() => {
    loadSearches();
  }, []);

  if (setSavedSearches.length === 0) {
    return <FullScreenSpinner />;
  }

  return (
    <div className="rpanefeatureinfo">
      <RPaneNavButton
        text="Property List"
        faIcon={faChevronLeft}
        onClick={() => {
          setComponentInRightPane("propertylist");
        }}
      />
      <div className="fi-header-single-heading pt-32 pb-32">
        <div className="larger-text">Saved Searches</div>
        <div className="smaller-text">
          You can edit your notification preferences within your{" "}
          <Link to={"/user/myaccount/notifications"}>account settings.</Link>
        </div>
      </div>

      <div className="fi-list no-hover">
        {savedSearches.map((feature, key) => {
          const { name, filter, id } = feature;

          if (!filter) return null;
          return (
            <div key={key} className="fi-list-item">
              <div className="fli-marker">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              <div className="fli-info">
                <div className="fli-location">{name}</div>
                <div className="fli-savedsearches">
                  <span
                    className="ss-view"
                    onClick={() => {
                      updateFilterString(filter);
                    }}
                  >
                    View Results
                  </span>

                  <span
                    className="ss-remove"
                    onClick={() => {
                      deleteSearch(id).then(_ => {
                        loadSearches();
                      });
                    }}
                  >
                    Remove
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RPaneSavedSearches;
