import axiosInstance from "./axiosInstance";

//https://api.inmybackyard.com/api/auth/login
export function apiLogin(email, password) {
  return axiosInstance.post("auth/login", { email, password });
}

export function apiLogOut() {
  return axiosInstance.delete("/auth/logout");
}

export function apiSignup(name, surname, company, email, password) {
  return axiosInstance.post("auth/register", {
    email,
    company,
    name,
    password,
    surname
  });
}

export function apiUserSettings() {
  return axiosInstance.get("user/settings");
}

export function apiUserUpdate(name, surname, email, company, mobile) {
  return axiosInstance.put("user/update", {
    name,
    surname,
    email,
    company,
    mobile
  });
}

export function apiUserProfile() {
  return axiosInstance.get("user");
}

export function apiUserCheckStripe() {
  return axiosInstance
    .get("auth/stripe/check")
    .then(p => p)
    .catch(e => {
      return { data: false };
    });
}

export function apiUpdateCard(token) {
  return axiosInstance.post("auth/stripe/update/card", { token });
}

export function apiRegisterCardAndPlan(token, plan) {
  return axiosInstance.post("auth/stripe/register", { token, plan });
}

export function apiSaveSearch(name, filter, notify) {
  return axiosInstance.post("user/search/create", {
    name,
    filter,
    notify
  });
}

export function apiGetSearches() {
  return axiosInstance.get("user/search");
}

export function apiDeleteSearch(id) {
  return axiosInstance.delete(`/user/search/${id}/delete`);
}

export function apiUpdateSearch(id, name, filter, notify) {
  return axiosInstance.put(`/user/search/${id}/update`, {
    name,
    filter,
    notify
  });
}

export function apiCancelAccount() {
  return axiosInstance.get("auth/stripe/cancel");
}

export function apiForgotPassword(email) {
  return axiosInstance.post("auth/password/forgot", { email });
}
