const typeList = [
  {
    label: "Subdivision",
    value: "17"
  },
  {
    label: "Single Residential",
    value: "1"
  },
  {
    label: "Small Transient Residential",
    value: "2"
  },
  {
    label: "Multi Residential",
    value: "3"
  },
  {
    label: "Transient Residential",
    value: "4"
  },
  {
    label: "Integrated Residential",
    value: "5"
  },
  {
    label: "Offices",
    value: "6"
  },
  {
    label: "Retail",
    value: "7"
  },
  {
    label: "Car Parking",
    value: "8"
  },
  {
    label: "Warehouse",
    value: "9"
  },
  {
    label: "Manufacturing",
    value: "10"
  },
  {
    label: "Healthcare",
    value: "11"
  },
  {
    label: "Assembly Building",
    value: "12"
  },
  {
    label: "Aged Care",
    value: "13"
  },
  {
    label: "Non Habitable Building",
    value: "14"
  },
  {
    label: "Ancilliary Structure",
    value: "15"
  },
  {
    label: "Bushfire Shelter",
    value: "16"
  }
];

export default typeList;
