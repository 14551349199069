import { useState } from "react";

const useModal = () => {
  const [modalStates, setModalState] = useState(new Map());

  const open = id => {
    const newModalState = new Map(modalStates);
    newModalState.set(id, true);
    setModalState(newModalState);
  };

  const close = id => {
    const newModalState = new Map(modalStates);
    newModalState.set(id, false);
    setModalState(newModalState);
  };

  const isOpen = id => {
    return modalStates.get(id) || false;
  };

  return {
    open,
    close,
    isOpen
  };
};

export default useModal;
