import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { RadioButton } from "@bluechilli/chilliforms";
import { useAppState } from "@bluechilli/bcstatemachine";
import mapViews from "../../constants/mapViews";
import DropDownContext from "../FilterDropDown/DropDownContext";

const mp = Object.keys(mapViews).map(k => {
  return { label: k.charAt(0).toUpperCase() + k.slice(1), value: k };
});

const MapViewTypes = () => {
  const [{ mapViewId }, { setMapViewId }] = useAppState("map");
  const { setIsOpened } = useContext(DropDownContext);

  const onChange = values => {
    setMapViewId(values.mapView);
    return {};
  };

  return (
    <>
      <Formik
        initialValues={{
          mapView: mapViewId
        }}
        validate={onChange}
        render={vals => {
          return (
            <Form>
              <RadioButton
                className="ddcompact"
                options={mp}
                name="mapView"
                value={true}
                onChange={() => {
                  setIsOpened(false);
                }}
              />
            </Form>
          );
        }}
      />
    </>
  );
};

export default MapViewTypes;
