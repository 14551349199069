import React from "react";
import "./rpanefeatureinfo.scss";
import { useAppState } from "@bluechilli/bcstatemachine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronRight,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import pluralise from "../../helpers/pluralise";
import RPaneNavButton from "./RPaneNavButton";
import Show from "@bluechilli/show";

const RPaneLocationList = () => {
  const [{ locations }, { setHoverLocation }] = useAppState("location");
  const { properties, applications, entries } = locations;

  const [
    { highlightPropertyId },
    { setRightMenu, setComponentInRightPane }
  ] = useAppState("mapviewlayout");

  return (
    <div className="rpanefeatureinfo">
      <RPaneNavButton
        text="close"
        faIcon={faChevronDown}
        onClick={() => {
          setRightMenu(false);
        }}
      />
      <div className="box-shadow-left-not">
        <div className="fi-header">
          <div>
            <em>{properties}</em> Properties
          </div>
          <div>
            <em>{applications}</em> Applications
          </div>
        </div>

        <div className="fi-list">
          <Show show={entries.length === 0} anim="fade">
            <div className="fi-noprops">
              No properties found which match this criteria
            </div>
          </Show>

          {entries.map((feature, key) => {
            const { location, applicationCount, location_id } = feature;

            return (
              <div
                className={`fi-list-item ${
                  location_id === highlightPropertyId ? "fi-active" : ""
                }`}
                key={key}
                onMouseOver={() => setHoverLocation(location_id)}
                onMouseOut={() => setHoverLocation(null)}
                onClick={() => {
                  setComponentInRightPane("property", location_id);
                  setHoverLocation(location_id);
                }}
              >
                <div className="fli-marker">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </div>
                <div className="fli-info">
                  <div className="fli-location">{location}</div>
                  <div className="fli-appcount">
                    {pluralise(applicationCount, "application")}
                  </div>
                </div>
                <div className="fli-chev">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RPaneLocationList;
