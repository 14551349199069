import React from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import RPanePropertyList from "../RPaneFeatureInfo/RPanePropertyList";
import RPaneProperty from "../RPaneFeatureInfo/RPaneProperty";
import RPaneApplication from "../RPaneFeatureInfo/RPaneApplication";
import RPanePropertyListNonFinancial from "../RPaneFeatureInfo/RPanePropertyNonFinancial";
import RPaneSavedSearches from "../RPaneFeatureInfo/RPaneSavedSearches";

const RightPaneInfo = () => {
  const [{ componentInRightPane }] = useAppState("mapviewlayout");
  const [{ isFinancial }] = useAppState("user");

  switch (componentInRightPane.component) {
    case "savedsearches":
      return <RPaneSavedSearches />;

    case "propertylist":
      return <RPanePropertyList />;

    case "property":
      if (!componentInRightPane.params) return <RPanePropertyList />;

      return isFinancial ? (
        <RPaneProperty propertyId={componentInRightPane.params} />
      ) : (
        <RPanePropertyListNonFinancial
          propertyId={componentInRightPane.params}
        />
      );

    case "application":
      return componentInRightPane.params ? (
        <RPaneApplication applicationId={componentInRightPane.params} />
      ) : (
        <RPanePropertyList />
      );

    default:
      return null;
  }
};

export default RightPaneInfo;
