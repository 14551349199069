import { useState, useEffect } from "react";
import throttle from "lodash/throttle";

const useMapViewLayout = () => {
  const [leftMenuIsOpen, setLeftMenuIsOpen] = useState(false);
  const [rightMenuIsOpen, setRightMenuIsOpen] = useState(false);
  const [screenSize, setScreenSize] = useState();
  const [componentInRightPane, _setComponentInRightPane] = useState({
    component: "propertylist",
    params: undefined
  });

  const [componentInLeftPane, _setComponentInLeftPane] = useState({
    component: "nothing",
    params: undefined
  });

  useEffect(() => {
    window.addEventListener("resize", assessWidth);
    assessWidth();
  });

  const assessWidth = throttle(() => {
    setScreenSize(screenWithToString);
  }, 700);

  const setLeftMenu = value => {
    setLeftMenuIsOpen(value);

    if (value === true && (screenSize === "small" || screenSize === "medium")) {
      setRightMenuIsOpen(false);
    }
  };

  const setRightMenu = value => {
    setRightMenuIsOpen(value);

    if (value === true && (screenSize === "small" || screenSize === "medium")) {
      setLeftMenuIsOpen(false);
    }
  };

  const setComponentInRightPane = (component, params) => {
    _setComponentInRightPane({ component, params });
    setRightMenu(true);
  };

  const setComponentInLeftPane = (component, params) => {
    _setComponentInLeftPane({ component, params });
    setLeftMenu(true);
  };

  return [
    {
      leftMenuIsOpen,
      rightMenuIsOpen,
      screenSize,
      componentInRightPane,
      componentInLeftPane
    },
    {
      setLeftMenu,
      setRightMenu,
      setComponentInRightPane,
      setComponentInLeftPane
    }
  ];
};

/* HELPERS */

const screenWithToString = () => {
  const w = window.innerWidth;
  if (w <= 768) return "small";
  if (w <= 1600) return "medium";
  return "large";
};

export default useMapViewLayout;
