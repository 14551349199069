import React, { useContext } from "react";
import { useAppState } from "@bluechilli/bcstatemachine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import DropDownContext from "../FilterDropDown/DropDownContext";
import SaveSearchButton from "../SaveSearchButton/SaveSearchButton";

const DDSavedSearch = () => {
  const { setIsOpened } = useContext(DropDownContext);

  const [{ componentInRightPane }, { setComponentInRightPane }] = useAppState(
    "mapviewlayout"
  );
  const [{ isFinancial }] = useAppState("user");

  const modal = useAppState("modal");
  const savedSearchesSelected =
    componentInRightPane.component === "savedsearches";

  return (
    <ul className="filterbar-nav">
      <li className="save-this-search">
        <span className="fbar-icon">
          <FontAwesomeIcon icon={faPlus} />
        </span>

        <SaveSearchButton
          className="in-filterbar"
          onClick={e => {
            setIsOpened(false);
            e.stopPropagation();
          }}
        />
      </li>
      <li className="saved-searches">
        <div
          onClick={e => {
            if (!isFinancial) {
              modal.open("paywall");

              return;
            }

            const targetComponent = !savedSearchesSelected
              ? "savedsearches"
              : "propertylist";

            setComponentInRightPane(targetComponent);

            setIsOpened(false);
            e.stopPropagation();
          }}
        >
          <span className="fbar-icon">
            <FontAwesomeIcon icon={faBookmark} />
          </span>
          saved searches
        </div>
      </li>
    </ul>
  );
};

export default DDSavedSearch;
