import React, { Component } from "react";
import { Form, Formik } from "formik";

import NextButton from "./NextButton";
import SubmitButton from "../SubmitButton/SubmitButton";
import PreviousButton from "./PreviousButton";

export default class FormWizard extends Component {
  static Page = ({ children, parentState }) => {
    return children(parentState);
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues
    };
  }

  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }));

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }));

  handleSubmit = (values, bag) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (!isLastPage) {
    }

    if (isLastPage) {
      return onSubmit(values, bag);
    } else {
      this.next(values);
      bag.setSubmitting(false);
    }
  };

  render() {
    const { children, validationSchema } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validationSchema={validationSchema[page]}
        onSubmit={this.handleSubmit}
        render={props => (
          <Form onSubmit={props.handleSubmit}>
            {React.cloneElement(activePage, { parentState: { ...props } })}
            <div>
              <div>
                {page > 0 && (
                  <div>
                    <PreviousButton
                      className="btn btn-primary"
                      value="Previous"
                      onClick={this.previous}
                    />
                  </div>
                )}

                <div>
                  {!isLastPage && (
                    <NextButton
                      className="btn btn-primary"
                      value="Next"
                      onClick={this.next}
                    />
                  )}
                  {isLastPage && (
                    <SubmitButton
                      className="form-button"
                      value="Finish"
                      submittingValue="Submitting..."
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      />
    );
  }
}
