import React, { useState } from "react";
import MediaQuery from "react-responsive";
import PropTypes from "prop-types";
//import "./menu.scss";
import MenuContext from "./context";
import Hamburger from "./Hambuger";
import Navbar from "./Navbar";

const ResponsiveMenu = props => {
  const { responsiveWidth, mobile, desktop, ...rest } = props;
  const [somethingIsOpen, setSomethingIsOpen] = useState(false);

  return (
    <MenuContext.Provider value={{ somethingIsOpen, setSomethingIsOpen }}>
      <MediaQuery maxWidth={responsiveWidth}>
        {matches => {
          if (matches) {
            return <Hamburger mobile={mobile} {...rest} />;
          }
          return <Navbar desktop={desktop} {...rest} />;
        }}
      </MediaQuery>
    </MenuContext.Provider>
  );
};

ResponsiveMenu.propTypes = {
  responsiveWidth: PropTypes.number.isRequired
};

ResponsiveMenu.defaultProps = {
  responsiveWidth: 767,
  desktopAlign: "left"
};

export default ResponsiveMenu;
