import React, { useEffect, useState } from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useAppState } from "@bluechilli/bcstatemachine";
import RPaneNavButton from "./RPaneNavButton";
import FullScreenSpinner from "@bluechilli/fullscreenspinner";
import ApplicationLabel from "../ApplicationLabel/ApplicationLabel";
import get from "lodash/get";

const RPaneApplication = ({ applicationId }) => {
  const { setComponentInRightPane } = useAppState("mapviewlayout")[1];
  const [{ selectedLocationData }] = useAppState("location");
  const { getApplication } = useAppState("application")[1];

  const [applicationData, setApplicationData] = useState();

  useEffect(() => {
    getApplication(applicationId).then(payload => {
      setApplicationData(payload.application);
    });
  }, [applicationId]);

  if (!applicationData) {
    return <FullScreenSpinner />;
  }

  const {
    no,
    tracking_url,

    description,
    submitted,
    decision_date,
    decision_authority,
    estimated_cost,
    estimated_cost_raw,
    authority,
    development_types_tags,
    application_types_tags,
    address,

    decision_category
  } = applicationData;

  const authorityName = get(authority, "name");
  const authorityEmail = get(authority, "email");
  const authorityURL = get(authority, "tracking_url");
  const decision_category_text = get(decision_category, [0, "name"]);

  const application_type = application_types_tags
    .map(att => get(att, "name"))
    .join(" ");

  const authorityLink = authorityEmail
    ? "mailto:" + authorityEmail
    : authorityURL;

  return (
    <div className="rpanefeatureinfo">
      <RPaneNavButton
        faIcon={faChevronLeft}
        onClick={() => {
          setComponentInRightPane("property", get(selectedLocationData, "id"));
        }}
      >
        Back
      </RPaneNavButton>
      <div className="fi-header-single-heading">
        <div className="app-label">
          <DevelopmentTypeTags data={development_types_tags} />
        </div>
        <div className="larger-text">{address}</div>
        <div className="smaller-text">{application_type}</div>
      </div>

      <div className="fi-content">
        <h2>About</h2>

        <AData label="id" data={no} />
        <AData label="Submitted" data={submitted} />
        <AData label="Authority" data={authorityName} />
        <AData
          label="Value"
          data={estimated_cost_raw}
          show={estimated_cost !== "0.00"}
        />

        <div className="mt-16 mb-32">{description}</div>

        <h2>Decision</h2>

        <AData label="Status" data={decision_category_text} />
        <AData label="Made by" data={decision_authority} />
        <AData label="Date" data={decision_date} />

        <div className="mt-32">
          <div className="pane-button">
            <a href={tracking_url} target="_blank" rel="noopener noreferrer">
              View Records
            </a>
          </div>

          <div className="pane-button-invert">
            <a href={authorityLink}>Contact Authority</a>
          </div>
        </div>
      </div>
    </div>
  );
};

const DevelopmentTypeTags = ({ data }) => {
  return data.map((d, k) => {
    return <ApplicationLabel txt={d.name} key={k} />;
  });
};

const AData = ({ label, data, show = true }) => {
  if (!show || show === false) return null;
  return (
    <div className="fi-content-line">
      <strong>{label}:</strong> {data}
    </div>
  );
};

export default RPaneApplication;
