import React from "react";
import { Form, Formik } from "formik";
import { useAppState } from "@bluechilli/bcstatemachine";

import {
  TextField,
  SubmitButton,
  FormError,
  ElementContainer,
  emitFormError
} from "@bluechilli/chilliforms";
import { Link, navigate } from "@reach/router";

import loginFormValidationSchema from "./loginFormValidationSchema";

function LoginForm() {
  const { login } = useAppState("user")[1];

  return (
    <>
      <Formik
        validationSchema={loginFormValidationSchema}
        initialValues={{
          email: "",
          password: ""
        }}
        onSubmit={(values, formikActions) => {
          login(values.email, values.password)
            .then(p => {
              navigate("/");
            })
            .catch(error => {
              emitFormError(formikActions, "Password Incorrect");
            });
        }}
        render={({ errors, isSubmitting }) => {
          return (
            <Form>
              <TextField
                label="Email Address"
                name="email"
                type="email"
                placeholder="Please type your email"
                autoFocus={true}
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                placeholder="********"
              />
              <FormError />
              <SubmitButton
                className="form-button"
                value="log in"
                submittingValue="logging in .."
              />

              <ElementContainer>
                <div className="form-bottomtext">
                  Forgotten your password?{" "}
                  <Link to="/user/forgot" className="form-link">
                    Reset
                  </Link>
                </div>
                <div className="form-bottomtext">
                  Don't have an account?{" "}
                  <Link to="/user/selectplan">Sign Up here </Link>
                </div>
              </ElementContainer>
            </Form>
          );
        }}
      />
    </>
  );
}

/*

 <div className="form-bottomtext">
                  <Link to="/user/forgot" className="form-link">
                    Reset password
                  </Link>
                </div>
 */

export default LoginForm;
