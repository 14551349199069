import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { LocationInputField } from "@bluechilli/chilliforms";
import "./addresssearch.scss";
import { useAppState } from "@bluechilli/bcstatemachine";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import googleAddressSearch from "@bluechilli/chilliforms/src/components/LocationInputField/googleAddressSearch";

const AddressSearch = props => {
  const [
    { searchString },
    { setCenter, setZoom, setSearchMarker }
  ] = useAppState("map");

  useEffect(() => {
    if (searchString) {
      googleAddressSearch(searchString).then(add => {
        const { lat, lng, suggestedZoom } = add;

        setCenter({ lat, lng });
        setTimeout(() => {
          setZoom(suggestedZoom);
        }, 500);

        setSearchMarker({ lat, lng });
      });
    }
  }, [searchString]);

  return (
    <div className="addresssearch">
      <Formik
        render={formValues => {
          return (
            <div>
              <Form>
                <LocationInputField
                  className="navbar-locationfield"
                  defaultValue={searchString}
                  name="address"
                  placeholder="Enter property address"
                  onSelect={add => {
                    const { lat, lng, suggestedZoom } = add;

                    setCenter({ lat, lng });
                    setTimeout(() => {
                      setZoom(suggestedZoom);
                    }, 500);

                    setSearchMarker({ lat, lng });
                  }}
                />

                <FontAwesomeIcon icon={faSearch} />
              </Form>
            </div>
          );
        }}
      />
    </div>
  );
};

export default AddressSearch;
