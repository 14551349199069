const appList = [
  {
    label: "Subdivision",
    value: "1"
  },
  {
    label: "Development Application",
    value: "3"
  },
  {
    label: "Modification",
    value: "4"
  },
  {
    label: "Complying Development",
    value: "6"
  },
  {
    label: "Construction Certificate",
    value: "8"
  },
  {
    label: "Occupation Certificate",
    value: "10"
  }
];

export default appList;
