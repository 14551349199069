import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { useAppState } from "@bluechilli/bcstatemachine";
import CheckBoxFieldArray from "@bluechilli/chilliforms/src/components/CheckboxFieldArray/CheckboxFieldArray";

import appList from "../../constants/appTypes";
import DropDownContext from "../FilterDropDown/DropDownContext";

const DDFilterApps = () => {
  const [{ apps }, { updateAppList }] = useAppState("filterPersistence");
  const { setIsOpened } = useContext(DropDownContext);

  const onChange = values => {
    updateAppList(values.resFilter);
    return {};
  };

  return (
    <>
      <Formik
        initialValues={{ resFilter: apps }}
        validate={onChange}
        render={({ setValues }) => {
          return (
            <Form>
              <CheckBoxFieldArray data={appList} name="resFilter" />
              <div className="dd-footer">
                <div
                  onClick={e => {
                    setValues({ resFilter: {} });
                    updateAppList({});
                    setIsOpened(false);
                    e.stopPropagation();
                  }}
                >
                  Clear
                </div>
              </div>
            </Form>
          );
        }}
      />
    </>
  );
};

export default DDFilterApps;
