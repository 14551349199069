import React from "react";
import { ElementWrap, InputWrap } from "../../Scaffolding/Scaffolding";

export default function ElementContainer(props) {
  const { name, className = "" } = props;

  return (
    <ElementWrap className={className}>
      <InputWrap name={name}>{props.children}</InputWrap>
    </ElementWrap>
  );
}
