import React from "react";
import "./panerightopener.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";

const PaneRightOpener = ({ open, onClick }) => {
  const openedClass = open === true ? "pro-opened" : "pro-closed";

  return (
    <div className={`panelrightopener ${openedClass}`} onClick={onClick}>
      <div className="pro-container">
        {!open && <FontAwesomeIcon icon={faChevronLeft} />}
        {open && <FontAwesomeIcon icon={faTimes} />}
      </div>
    </div>
  );
};

export default PaneRightOpener;
