import React from "react";
import { Field, Form } from "formik";
import get from "lodash/get";
import {
  ElementWrap,
  HelperText,
  ErrorContainer,
  LabelWrap,
  InputWrap
} from "../../Scaffolding/Scaffolding";

const CheckBoxFieldArray = ({
  data,
  name,
  onClick = () => {},
  className = ""
}) => {
  return (
    <React.Fragment>
      {data.map(({ label, value }, k) => (
        <CheckboxField
          className={className}
          key={k}
          label={label}
          parent={name}
          value={value}
          onClick={(name, checked) => {
            onClick(name, checked, label, value);
          }}
        />
      ))}
    </React.Fragment>
  );
};

function CheckboxField(props) {
  const {
    label,
    helperText = "",
    parent,
    className = "",
    value,
    onClick = () => {}
  } = props;

  const name = `${parent}[${value}]`;

  const onClickHandler = (name, checked) => {
    onClick(name, checked);
  };

  return (
    <ElementWrap className={`checkbox ${className}`} name={name}>
      <InputWrap name={name}>
        <Field
          name={name}
          type="checkbox"
          value={value}
          render={({ field, form }) => {
            //console.log("__", field, form);

            const checked = !!get(form, ["values", parent, value]);
            return (
              <input
                id={name}
                className={`checkbox ${className}`}
                type="checkbox"
                checked={checked}
                onClick={() => {
                  onClickHandler(name, checked);
                }}
                {...field}
              />
            );
          }}
        />
        <span className={` checkbox checked`} />
      </InputWrap>
      <LabelWrap name={name} label={label}>
        <label className={`form-element-label ${className}`} htmlFor={name}>
          {label || name}
        </label>
        <HelperText text={helperText} />
      </LabelWrap>

      <ErrorContainer name={name} />
    </ElementWrap>
  );
}

export default CheckBoxFieldArray;
