import React from "react";
import { connect } from "formik";

import { ElementWrap, InputWrap } from "../../Scaffolding/Scaffolding";

function PreviousButton(props) {
  const { name, className = "", value = "Next", onClick } = props;

  return (
    <ElementWrap name={name}>
      <InputWrap name={name}>
        <button className={className} onClick={onClick}>
          {value}
        </button>
      </InputWrap>
    </ElementWrap>
  );
}

export default connect(PreviousButton);
