import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PlanPane = ({
  price,
  priceOverride,
  img,
  name,
  descriptions = [],
  selected,
  onClick
}) => {
  return (
    <div className={`planpane ${selected ? "planpane-selected" : ""}`}>
      <div className="pp-img">
        <img src={img} alt={name} />
      </div>

      {price && (
        <div className="pp-price">
          <span className="pdollar">$</span>
          <span className="pprice">{price}</span>
          <span className="pmonth">/month</span>
        </div>
      )}

      {priceOverride && (
        <div className="pp-price">
          <span className="pprice">{priceOverride}</span>
        </div>
      )}

      <div className="pp-name">{name}</div>

      <div className="pp-desc">
        <p>All of the above inclusions plus:</p>
        {descriptions.map((desc, k) => {
          return (
            <div className="pp-descline" key={k}>
              <ul className="fa-ul">
                <li>
                  <span className="fa-li">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span className="pp-desctext">{desc}</span>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
      <div className="pp-button">
        <button className="select-plan-button" onClick={() => onClick()}>
          select plan
        </button>
      </div>
    </div>
  );
};

/*
     className="select-plan-button"
          onClick={() => {
            setDesiredPlan(plan);
            if (isLoggedIn) {
              navigate("/user/payment");
            } else {
              navigate("/user/signup");
            }
          }}
        >
          next

     */
export default PlanPane;
