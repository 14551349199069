import React from "react";
import Login from "./Login/Login";
import Signup from "./Signup/Signup";
import { Router } from "@reach/router";
import LogOut from "./LogOut/LogOut";
import SelectPlan from "./SelectPlan/SelectPlan";
import Payment from "./Payment/Payment";
import ProtectedRoute from "./ProtectedRoute";

import MyAccount from "./MyAccount/index";
import MyDetails from "./MyAccount/MyDetails";
import Notifications from "./MyAccount/Notifications";
import Billing from "./MyAccount/Billing";
import ForgotPassword from "./ForgotPassword/ForgotPassword";

const Account = () => {
  return (
    <Router>
      <Signup path="signup" />
      <Login path="login" />
      <LogOut path="logout" />
      <ForgotPassword path="forgot" />
      <SelectPlan path="selectplan" />
      <ProtectedRoute component={Payment} path="payment" />

      <MyAccount path="myaccount">
        <MyDetails path="mydetails" default />
        <Notifications path="notifications" />
        <Billing path="billing" />
      </MyAccount>
    </Router>
  );
};

export default Account;
