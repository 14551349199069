import React, { useContext } from "react";
import Link from "./Link";
import menuContext from "./context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";

const MenuItem = ({
  style,
  className,
  onClick = () => {},
  activeClassName,
  to,
  hideIf,
  showIf,
  icon,
  children
}) => {
  if (hideIf === true) return null;
  if (showIf === false) return null;
  const context = useContext(menuContext);

  return (
    <li className="navbar-responsive__list-item" onClick={onClick}>
      <Link
        onClick={() => {
          context.setSomethingIsOpen(false);
        }}
        to={to}
        className={className}
        activeClassName={activeClassName}
        style={style || null}
      >
        {icon && (
          <span className="menu-icon">
            <FontAwesomeIcon icon={faUserCircle} />
          </span>
        )}
        {children}
      </Link>
    </li>
  );
};

export default MenuItem;
