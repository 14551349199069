import React from "react";
import "./fourohfour.scss";

const FourOhFour = ({ children }) => (
  <div className="fourohfour-container-bg">
    <h1>404</h1>
    <h2>It looks like this page is not found</h2>
    {children}
  </div>
);

export default FourOhFour;
