import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import axiosInstance from "../../api/axiosInstance";
import { useAppState } from "@bluechilli/bcstatemachine";
import get from "lodash/get";
const sesh = require("sessionstorage");

let _isLoggedIn;

const AxiosMiddleareWrapper = props => {
  const [{ isLoggedIn }, { logout }] = useAppState("user");

  useEffect(() => {
    _isLoggedIn = isLoggedIn;
  });

  useEffect(() => {
    // Add a response interceptor
    axiosInstance.interceptors.response.use(
      function(response) {
        return response.data;
      },
      function(error) {
        if (get(error, "response.status") === 401) {
          setTimeout(() => {
            if (_isLoggedIn) {
              logout("fromAxios");
              navigate("/");
            }
          }, 100);
        }
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.request.use(
      function(config) {
        // Do something before request is sent
        const seshToken = sesh.getItem("token");

        if (seshToken) {
          config.headers.Authorization = "Bearer " + sesh.getItem("token");
        }

        return config;
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }, []);

  return <>{props.children}</>;
};

export default AxiosMiddleareWrapper;
