import { useState, useEffect } from "react";
import {
  apiCancelAccount,
  apiDeleteSearch,
  apiForgotPassword,
  apiGetSearches,
  apiLogin,
  apiLogOut,
  apiRegisterCardAndPlan,
  apiSaveSearch,
  apiSignup,
  apiUpdateCard,
  apiUpdateSearch,
  apiUserCheckStripe,
  apiUserProfile,
  apiUserSettings,
  apiUserUpdate
} from "../api/user";
const sesh = require("sessionstorage");

const useUser = () => {
  const [token, setToken] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({
    profile: {},
    user: {},
    stripe: {}
  });
  const [isFinancial, setIsFinancial] = useState(null);
  const [desiredPlan, setDesiredPlan] = useState(0);

  useEffect(() => {
    if (token === "deleteme") {
      sesh.removeItem("token");
      setToken(null);
    } else if (token) {
      sesh.setItem("token", token);
    }
  }, [token]);

  const loginFromToken = _token => {
    setToken(_token);
    setIsLoggedIn(true);
  };

  const logout = async (force = false) => {
    if (isLoggedIn || force) {
      apiLogOut().finally(() => {
        setUserData({
          profile: {},
          user: {},
          stripe: {}
        });
        setIsLoggedIn(false);
        setIsFinancial(null);
        setToken("deleteme");
      });
    }
  };

  const signup = (firstname, lastname, company, email, password) => {
    return apiSignup(firstname, lastname, company, email, password);
  };

  const getUserData = async () => {
    const profile = await apiUserProfile();
    const user = await apiUserSettings();
    const stripe = await apiUserCheckStripe();

    const _isFinancial = stripe.data !== false;

    setUserData({ profile, user, stripe, isFinancial: _isFinancial });
    setIsFinancial(_isFinancial);
    return { profile, user, stripe, isFinancial: _isFinancial };
  };

  const updateUser = (name, surname, email, company, mobile) => {
    return apiUserUpdate(name, surname, email, company, mobile);
  };

  const login = (email, password) => {
    return apiLogin(email, password).then(payload => {
      const _token = payload.data.token;
      setToken(_token);
      setIsLoggedIn(true);
    });
  };

  const updateCard = token => apiUpdateCard(token);

  const registerCardAndPlan = (token, planId) =>
    apiRegisterCardAndPlan(token, planId);

  const saveSearch = (name, filter, notify) =>
    apiSaveSearch(name, filter, notify);

  const updateSearch = (id, name, filter, notify) =>
    apiUpdateSearch(id, name, filter, notify);

  const getSearches = () => apiGetSearches();

  const deleteSearch = id => apiDeleteSearch(id);

  const cancelAccount = () => apiCancelAccount();

  const forgotPassword = email => apiForgotPassword(email);

  return [
    { isLoggedIn, userData, desiredPlan, isFinancial },
    {
      login,
      signup,
      setIsLoggedIn,
      loginFromToken,
      logout,
      getUserData,
      setDesiredPlan,
      updateCard,
      registerCardAndPlan,
      saveSearch,
      getSearches,
      deleteSearch,
      updateSearch,
      updateUser,
      cancelAccount,
      forgotPassword
    }
  ];
};

export default useUser;
