import { useState } from "react";
import { apiGetApplication } from "../api/property";

const useApplication = () => {
  const [currentApplicationId, setCurrentApplicationId] = useState();

  const getApplication = id => {
    return apiGetApplication(id).then(payload => {
      return payload;
    });
  };
  return [
    { currentApplicationId },
    { setCurrentApplicationId, getApplication }
  ];
};

export default useApplication;
