import React from "react";
import ImageUpload from "./ImageUpload";
import { connect } from "formik";

import {
  ElementWrap,
  LabelWrap,
  HelperText,
  ErrorContainer,
  InputWrap
} from "../../Scaffolding/Scaffolding";

function ImageUploadField({
  label,
  name,
  helperText = "",
  className = "",
  placeholder = "Select...",
  customPlusIcon,
  formik
}) {
  const { setFieldTouched, setFieldValue } = formik;
  const value = formik.values[name];

  return (
    <ElementWrap className={className}>
      <LabelWrap name={name} label={label}>
        <label htmlFor={name}>{label || name}</label>
        <HelperText text={helperText} />
      </LabelWrap>
      <InputWrap name={name}>
        <ImageUpload
          placeholderImagePath={placeholder}
          name={name}
          onBlur={() => setFieldTouched(name, true)}
          customPlusIcon={customPlusIcon}
          handleUpdatePromise={value => {
            setFieldValue(name, value[0]);
            // doing this here to support async if required, but also because
            // the component itself wont be doing async stuff until the form is submitted
            return Promise.resolve(true);
          }}
          value={value}
        />
      </InputWrap>
      <ErrorContainer name={name} />
    </ElementWrap>
  );
}

export default connect(ImageUploadField);
