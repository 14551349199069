const plans = {
  national: "plan_FS4C6hGeaucumY",
  state: "plan_FS4CrCsuFUkg7m",
  regional: "plan_FS4BzdtOLOP5OT",
  local: "plan_FS4AePzveLxuOF"

  /* Testing Plans
        national: "plan_FFL8J9BgogXJR6",
        state: "plan_FFL71lwNLhMYmT",
        regional: "plan_FRrxS3A567CqL1",
        local: "plan_FFL6MZ2fjk0A1w"
    */
};

export default plans;
