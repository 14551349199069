import React from "react";
import { connect } from "formik";
import PhoneInput from "react-phone-number-input";
import {
  ElementWrap,
  LabelWrap,
  HelperText,
  InputWrap,
  ErrorContainer
} from "../../Scaffolding/Scaffolding";

import "react-phone-number-input/style.css";

const PhoneNumberInput = props => {
  const { setFieldValue, setFieldTouched, values } = props.formik;
  const {
    label,
    name,
    defaultValue,
    helperText = "",
    className = "",
    placeholder = "Enter your phone number",
    onChange = () => {},
    country = undefined
  } = props;

  let actualValue = defaultValue || values[name];

  //console.log(props);

  return (
    <ElementWrap className={className}>
      <LabelWrap name={name} label={label}>
        <label htmlFor={name}>{label || name}</label>
        <HelperText text={helperText} />
      </LabelWrap>
      <InputWrap name={name}>
        <PhoneInput
          country={country}
          name={name}
          id={name}
          onChange={value => {
            setFieldValue(name, value ? value : "");
            onChange(value);
            setFieldTouched(name, true);
          }}
          onBlur={() => setFieldTouched(name, true)}
          placeholder={placeholder}
          value={actualValue}
        />
      </InputWrap>
      <ErrorContainer name={name} />
    </ElementWrap>
  );
};

export default connect(PhoneNumberInput);
