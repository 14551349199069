import React, { useEffect, useState } from "react";
import { Loader } from "@bluechilli/spinners";

export default function FullScreenSpinner({
  txt,
  spinner: Spinner,
  useAllScreen = false
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const to = setTimeout(() => {
      setShow(true);
    }, 500);

    return () => clearTimeout(to);
  }, []);

  const style = useAllScreen ? { top: 0, right: 0 } : {};

  return (
    <div
      style={style}
      className={`fs-spinner-container-bg ${
        show ? "fs-spinner-container-bg-show" : "fs-spinner-container-bg-hide"
      }`}
    >
      {Spinner ? <Spinner /> : <Loader />}
      {txt && <div className="fs-info-text">{txt}</div>}
    </div>
  );
}

//<Loader />
